.segment-check-list {
  margin-bottom: 20px;
  h6 {
    font-weight: bold;
  }
  p {
    line-height: 1.25;
  }

  .nomination-price {
    .nomination-label-full-price {
      font-weight: bold;
      color: $primary-label-color;
    }

    .discounted-entry-nomination {
      color: $red;
    }
  }

  .segments-visible {
    margin-bottom: 20px;
    display: flex;

    .segment-label {
      margin-left: 20px;
    }
  }
}
