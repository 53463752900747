@mixin icon {
  height: 15px;
  width: 15px;
  stroke: $white;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center center;
}

.view-preferences {
  .preference-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    .preference-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1rem;
      font-weight: bold;

      .check-mark-icon {
        @include icon;
        border: 1px solid $pref-green-color;
        background-color: $pref-green-color;
      }

      .x-icon {
        @include icon;
        border: 1px solid $redError;
        background-color: $redError;
      }

      .preference-date {
        font-weight: 500;
      }
    }
  }

  .no-preference {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;

    .info-icon {
      @include icon;
      display: block;
      text-indent: 1px;
      line-height: 15px;
      text-align: center;
      background: $secondary-brand-color;
      border: 1px solid $secondary-brand-color;
      color: $info-icon-color;
    }
  }

  .text {
    padding-left: 9px;
  }

  .opt-out-text {
    font-style: italic;
  }
}
