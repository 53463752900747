.my-rank {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  @include breakpoint($desktop) {
    margin: 0;
    padding: 15px 0;
  }

  span.my-points {
    float: right;
    color: $white;
  }
  label.my-rank-label {
    font-size: 1rem;
    align-self: center;
  }
}

.leaderboard-right-column {
  @include breakpoint($desktop) {
    padding-top: 50px;
    padding-left: 60px;
  }

  .leaderboard-form-container {
    max-width: 610px;
    margin: 0 auto;
  }

  .column-right-fix {
    @include breakpoint($desktop) {
      height: 0;
    }
  }

  .youth-athlete-rank {
    @include breakpoint($desktop) {
      margin-top: 18px;
    }

    .my-rank {
      margin-top: 0;
      border-top: 2px solid #7e7e7e;
      padding-top: 15px;

      @include breakpoint($desktop) {
        margin: 0;
        padding: 10px 0;
      }
    }
  }

  .select-season-wrp {
    @include breakpoint($desktop) {
      padding-top: 50px;
    }

    .year-label {
      display: inline-block;
      padding-right: 10px;
    }
  }
  ol.leaderboard-list {
    list-style: none;
    margin: 0;
    padding: 0 0 50px 0;

    .leaderboard-column-headers {
      display: flex;
      justify-content: space-between;

      span {
        color: $dark-gray2;
        font-size: 14px;
        letter-spacing: 2.1px;
        line-height: 17px;
        text-transform: uppercase;
      }

      span:first-child {
        flex: 0 0 80%;
      }

      span:last-child {
        min-width: 60px;
        flex: 0 0 15%;
      }
    }

    li {
      margin: 18px 0;
      padding: 0;

      .athlete-container.current-athlete {
        background: $primary-brand-color;

        .name-column {
          .full-name {
            color: $black;
          }
        }
        .points-column {
          .points {
            color: black;
          }
        }
      }

      .athlete-container {
        display: flex;
        justify-content: space-between;
        padding: 12px 0 12px 10px;

        .name-column {
          flex: 0 0 80%;

          .full-name {
            text-transform: capitalize;
            word-break: break-all;
          }
        }

        .points-column {
          min-width: 60px;
          flex: 0 0 15%;
        }
      }

      .position {
        margin-right: 10px;
        color: $gray;
      }
    }
  }
}

// Leader board form
form.leaderboard-form {
  label {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.note-leaderboard-update {
  display: block;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0;
  padding-bottom: 25px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 1px;
}

.blank-slate-leaderboard {
  background-color: rgba(255, 196, 4, 0.1);
  display: block;
  text-align: center;
  padding: 35px;
  letter-spacing: 0;
  border: 1px #999 dashed;
}

.leaderboard-link {
  color: $white;
}
.qualifying-cut-off {
  border-bottom: 3px dashed #7e7e7e !important;
}

.qualifying-cut-off-legend {
  border-bottom: 3px dashed #7e7e7e; 
  width: 50px;
  display: inline-block;
  margin-right: 10px;
}
