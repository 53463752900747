.entry-event-filters-modal {
  .footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    .btn-main {
      margin: 0px auto;
    }

    .clear-filter-btn {
      color: $wcra-primary-ret-secondary-brand-color;
      border: none;
      padding: 30px 0;
      font-size: 16px;
      letter-spacing: 2px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
