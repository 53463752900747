.partner-section {
  .check-mark-icon {
    height: 18px;
    width: 18px;
    stroke: $white;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid $entry-partner-invite-success;
    background-color: $entry-partner-invite-success;
    background-position: center center;
  }

  .email-icon {
    height: 20px;
    width: 16px;
    background-repeat: no-repeat;
    stroke: $secondary-brand-color;
    background-position: center center;
  }

  .email-invite-link {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    color: $secondary-brand-color;
    font-size: 13px;
    height: 18px;
    font-weight: 500;
    letter-spacing: 1.63px;
    text-transform: uppercase;
    width: fit-content;
    line-height: 18px;

    .invite {
      padding-left: 10px;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &-invited {
      color: $entry-partner-invite-success;
      pointer-events: none;
      &:hover {
        cursor: default;
        text-decoration: none;
      }
    }
  }
}
