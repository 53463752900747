.ReactTags__tag {
  display: inline-block;
  margin-right: 25px;
  margin-top: 3px;
  position: relative;
  text-transform: uppercase;

  &::before {
    content: '#';
  }
  &:hover {
    cursor: pointer;
  }

  .ReactTags__remove {
    font-size: 18px;
    position: absolute;
    right: -10px;
    text-transform: lowercase;
    top: -8px;
  }
}
