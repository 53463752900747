// COLORS
$black: #0f0f0f;
$gray: #9d9d9d;
$medium-gray: #606060;
$medium-gray2: #5a5a5a;
$dark-gray: #313131;
$dark-gray2: #7e7e7e;
$white: #ffffff;
$green: #8ce04a;
$dark-green: #10ac84;
$red: #ff5349;
$dark-red: #461e1e;
$yellow: #ffc404;

// CUSTOM BRAND COLORS
$primary-brand-color: #ffc404;
$primary-brand-color-darker: #c59803;
$primary-outline-color: #ffc404;
$secondary-brand-color: #ffc404;
$hr-color: $dark-gray2;
$hr-modal-color: $dark-gray;
$primary-brand-color-opacity: rgba(255, 196, 4, 0.5);
$redError: $red;

// Used when a color is primary for WCRA and secondary for RET
$wcra-primary-ret-secondary-brand-color: $primary-brand-color;

// CUSTOM LAYOUT/FONT COLORS
$primary-bg-color: $black;
$secondary-bg-color: $dark-gray;
$hr-bg-color: $dark-gray2;
$primary-label-color: $gray;
$primary-input-color: $white;
$primary-placeholder-color: $dark-gray2;
$primary-text-color: $white;
$disabled-button-color: $gray;
$banner-text-color: $black;
$btn-focus-color: $black;
$secondary-btn-focus-color: $white;
$primary-btn-bg: $primary-brand-color;
$primary-btn-hover: $primary-brand-color-darker;
$primary-btn-border: $primary-brand-color;
$primary-btn-text-color: $dark-gray;
$secondary-btn-bg: transparent;
$secondary-btn-hover: rgba(255, 196, 4, 0.5);
$secondary-btn-border: $primary-brand-color;
$back-button-color: $primary-brand-color;
$view-cart-btn-text: $dark-gray;
$view-cart-btn-background: $primary-brand-color;
$event-result-border: $primary-brand-color;
$select-value-color: $white;
$select-value-bg-color: $black;
$select-value-bg-hover: $dark-gray;
$select-value-selected: $primary-brand-color;
$event-result-border-hover: $primary-brand-color;
$agreements-label-color: $white;
$error-msg-bg-color: $dark-red;
$error-message-text-color: $white;
$saved-cards-hover-color: $white;
$entry-preference-bg-color: $dark-gray;
$entry-cant-compete-border-color: $red;
$event-title-color: $white;
$dashboard-options-color: $gray;
$profile-name-color: $white;
$profile-location-color: $gray;
$gift-card-total-balance-color: $primary-brand-color;
$profile-discipline-color: $white;
$disabled-pref-color: $white;
$current-entries-color: #dcdbdc;
$event-pref-border: $primary-brand-color;
$event-pref-selected-color: $white;
$pref-green-color: $green;
$disabled-opacity: 0.5;
$checkbox-label-color: $white;
$home-subheader-color: $white;
$home-signinwith-color: $white;
$section-title-padding: 30px;
$edit-card-color: red;
$remove-card-color: gray;
$edit-card-text-color: $white;
$date-item-default-color: $primary-brand-color;
$checkbox-inactive-border: $primary-brand-color;
$checkmark-color: $dark-gray;
$checkbox-disabled-border: $gray;
$multi-select-container-bg: $dark-gray;
$loading-spinner-color: $white;
$remove-preference-color: $white;
$container-mobile-padding: 12px;
$review-selected-discipline-bg-color: $dark-gray;
$preference-icon-color: $primary-brand-color;
$preferences-bg-color: $primary-brand-color;
$preference-card-bg-color: $black;
$date-item-color: $primary-brand-color;
$preference-card-opt-out-color: $white;
$preference-card-opt-in-color: $white;
$entries-count-bg-color: #313131;
$preference-card-opt-out-date-color: $black;
$alliance-tag-color: $primary-brand-color;
$alliance-label-color: #222f3e;
$event-search-card-bg-color: $dark-gray;
$multi-select-bg-color: $primary-brand-color;
$multi-select-color: $dark-gray;
$multi-select-item-bg-color: #313131;
$event-filter-color: $dark-gray;
$event-type-hover-color: $gray;
$entry-partner-search-option-font-color: $dark-gray;
$entry-partner-search-place-holder: $dark-gray;
$entry-partner-invite-success: $green;
$entry-discipline-modal-arrow-active: $white;
$entry-discipline-modal-arrow-disabled: $gray;
$optional-label: $primary-outline-color;

$info-icon-color: $dark-gray;

// FONTS
$primary-font: 'Barlow', san-serif;

// BREAKPOINTS
$tablet: medium;
$desktop: large;
$phone-width: 768px;
$tablet-width: 1024px;
$narrow-phone-smaller-width: 'only screen and (max-width : 320px)';
$narrow-phone-width: 'only screen and (max-width : 399px)';
$narrow-phone-bigger-width: 'only screen and (max-width : 415px)';

// LAYOUT
$footer_offset_bottom: 220px;
$header-height: 80px;

// OTHER
$transition_speed_mid: 0.3s;
$transition_speed_long: 0.5s;

.bg-dashboard {
  background-image: url('../../assets/img/background-images/bg-dashboard.jpg');
}
.bg-leaderboard {
  background-image: url('../../assets/img/background-images/bg-leaderboard2.jpg');
}
.bg-nominate {
  background-image: url('../../assets/img/background-images/bg-wm-br.jpg');
}
.bg-user {
  background-image: url('../../assets/img/background-images/bg-wm-br.jpg');
}
.bg-home {
  background-image: url('../../assets/img/background-images/bg-wm-br.jpg');
}
.bg-events {
  background-image: url('../../assets/img/background-images/bg-events.jpg');
}
.bg-leaderboard-1 {
  background-image: url('../../assets/img/background-images/bg-leaderboard.jpg');
}

@mixin pref-selected-pseduo-element {
  background: url('../../assets/img/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: 1px 5px;
  background-size: 17px 10px;
  border: 1px solid $green;
  background-color: $green;
}

@mixin out-selected-pseduo-element {
  border: 2px solid $black;
  background-color: $red;
}

@mixin pref-disabled-pseduo-element {
  border: 2px solid $black;
  background-color: #721717;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: yellow !important;
  font-size: 20px;
}
.PrivateTabIndicator-colorSecondary-4 {
  background-color: #8ce04a !important;
}
.MuiTab-textColorInherit.Mui-selected {
  color: $primary-brand-color-darker;
}

.theme-content-container {
  margin: 0 auto;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: $primary-brand-color !important;
}
.event-result .association-short-name,
.event-result .associations-finish-tag {
  background-color: $primary-brand-color !important;
}

@mixin partner-options-shadow {
  box-shadow: initial;
}

.warning-icon {
  img {
    filter: invert(88%) sepia(4%) saturate(100) hue-rotate(-24deg)
      brightness(100%) contrast(100%);
  }
}

.theme-content-container.card-content.stacked-content-container {
  margin-top: 70px;
}

.black-tooltip,
.black-tooltip-arrow:before {
  border: 2px solid $primary-brand-color;
}

.tooltip-width {
  width: 60%;
}
