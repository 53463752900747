#liability-content {
  overflow: hidden;
}

.container-liability {
  overflow: hidden;

  .section-content {
    overflow: auto;
    height: 400px;

    label,
    input {
      font-size: 16px;
    }

    .Select-control {
      margin-top: 3.5px !important;
    }

    .Select-input {
      input {
        margin-top: 8px;
        margin-left: -10px;
      }
    }

    .Select-option,
    .Select-placeholder,
    .Select-value {
      font-size: 16px;
      letter-spacing: normal;
    }

    hr {
      margin-top: 0;
    }

    label {
      margin-top: -8px;
    }

    .legal-consent-minor-p1,
    .youth-athlete-name {
      display: inline-block;
      float: left;
    }

    .youth-athlete-name {
      font-weight: bold;
      text-transform: capitalize;
      float: right;
      display: contents;

      div {
        display: contents;
      }
    }

    .legal-consent-minor-p2 {
      display: contents;
    }

    .section-address,
    .section-signature,
    .section-disclosure {
      padding-top: 50px;
    }

    .section-address,
    .section-signature {
      .title {
        font-weight: bold;
        font-size: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: -9px;
      }

      .error-message {
      }
    }

    .fine-print {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.77px;
      margin-top: 1rem;
    }

    .section-address {
      hr {
        margin: 0;
      }

      .half {
        width: 50%;
      }

      label {
        margin-top: 1rem;
      }

      .row-wrapper {
        display: flex;
        align-content: space-between;

        .full {
          width: 100%;
        }

        .half {
          width: 50%;
          float: left;

          input:focus {
            outline: none;
          }
        }

        .half-right {
          width: 50%;
          float: left;
          padding-left: 16px;
        }
      }
    }

    .section-disclosure {
      width: 100%;
      float: left;
      padding-top: 33px;
      text-transform: uppercase;
      letter-spacing: 2px;
      line-height: 19px;

      .form-checkbox-wrapper {
        margin: 0;

        label {
          margin-top: -30px;
          margin-left: 40px;
          line-height: 30px;
        }
      }
    }

    @media (max-width: $phone-width) {
      height: 310px;

      .section-disclosure {
        label {
          margin-top: -38px;
          line-height: 16px;
        }
      }
    }
  }
}
