.entry-add-buddy-card {
  margin-top: 0 !important;
  margin-bottom: 30px;

  .buddy {
    .buddy-name {
      font-size: 18px;
      letter-spacing: 2.7px;
      font-family: $primary-font;
      font-weight: bold;
      margin-bottom: 6px;
      text-transform: uppercase;
    }

    .buddy-manager {
      font-size: 1rem;
      color: $primary-brand-color;
      font-family: $primary-font;
      margin-bottom: 5px;
      letter-spacing: 1px;
    }

    .price-total-label,
    .price-total {
      font-size: 18px;
      letter-spacing: 2.7px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .price-total {
      color: $primary-brand-color;
    }

    hr {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.nomination-confirmation.entry-confirmation.theme-content-container {
  margin-top: 36px;
}

.nomination-confirmation.entry-confirmation.stacked-content-container {
  @media (min-width: 64em) {
    width: calc(50% + 100px);
  }

  .stacked-content-container {
    border-top: 2px solid $hr-color;
    margin-top: 15px !important;
    margin-bottom: 30px;
    padding-top: 15px;
  }
}
