.disciplines-subtext {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 19px;
}

.multi-select-wrapper {
  & > div:first-child {
    position: relative;
  }

  &.generic-comp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: wrap;
    margin-bottom: 25px;
    hr {
      margin: 5px auto;
      display: block;
      width: 100%;
      + label {
        line-height: unset;
        text-align: left !important;
        font-size: 16px;
        display: block;
        letter-spacing: 1.5px;
        width: 100%;
        margin: 0px 5px !important;
      }
    }

    .dropdown-button-container {
      margin: 0px 5px !important;
      height: unset;

      .dropdown-button {
        display: flex;
        width: 100%;
        align-items: center;
        .Select-value-label {
          font-size: 16px;
          line-height: unset;
          opacity: 0.5;
        }

        .Select-arrow-zone {
          padding-right: 0px;
        }
      }
    }

    .multi-select-container {
      max-height: 60vh;
      overflow-y: auto;
      width: 98%;
      top: 70px;
      min-height: fit-content;
      padding: 0px 0px 55px;

      .multi-select-scroll {
        max-height: 35vh;
        padding: 10px 20px 0px;
        position: relative;

        .form-checkbox-wrapper {
          padding-bottom: 0px;
          min-height: 40px;
          margin: 0px -15px 5px -15px;
          padding: 0px 20px;
          .checkbox-label {
            height: inherit;
            cursor: pointer;
            background: transparent;
            padding-left: 15px;
          }
          &:hover {
            background-color: rgba($review-selected-discipline-bg-color, 0.3);
          }
        }
      }

      .add-disc-container {
        position: absolute;
        bottom: 0px;
        width: 100%;

        button {
          height: 50px;
          color: $multi-select-color;
          font-size: 16px;
          font-weight: normal;
          border: none;
        }
      }
    }

    .multi-selected-items-container {
      width: 100%;
      min-height: 25vh;
      max-height: 300px;
      max-height: -webkit-fill-available;
      overflow-y: auto;

      @media (max-width: 767px) {
        height: calc(100vh - 493px);
      }

      .multi-selected-items {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        align-items: center;
        margin: 0.7em 0;
        padding: 0.75em 1em;
        color: $primary-text-color;
        background-color: $multi-select-item-bg-color;
        font-weight: 700;
        text-transform: uppercase;

        button {
          &:hover {
            cursor: pointer;
          }

          svg {
            stroke: $preference-icon-color;
            fill: none;
          }
        }
      }
    }
  }

  .dropdown-button-container {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    .dropdown-button {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: $gray;
      font-size: 20px;
      text-align: left;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }

      .Select-value-label {
        background-color: transparent;
        color: rgba($gray, 0.7) !important;
        display: block;
        font-size: 20px;
        line-height: 16px;
        padding: 0;
        position: relative;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }

  .Select-value {
    line-height: normal !important;
  }

  .select-disciplines-label {
    margin-top: 50px;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    line-height: normal;
    font-weight: bold;
    display: block;
  }

  hr {
    margin: 10px 0px !important;

    &:first-child {
      margin-top: 0px !important;
    }
  }

  .select-disciplines-sublabel {
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
  }

  .multi-select-container {
    width: 100%;
    max-height: 320px;
    position: absolute;
    top: 100%;
    background: $multi-select-container-bg;
    overflow: hidden;
    z-index: 10;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);

    .multi-select-scroll {
      height: 100%;
      max-height: 252px;
      padding-top: 24px;
      padding-bottom: 16px;
      overflow-y: scroll;
      padding: 18px;
    }

    .closed-disc-text {
      padding: 1em 0;

      > h3 {
        font-family: $primary-font;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2.4px;
        line-height: 19px;
        text-transform: uppercase;
      }

      > span {
        display: inline-block;
        font-family: $primary-font;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: normal;
        line-height: 14px;
      }
    }

    .form-checkbox-wrapper {
      align-items: center;

      .checkbox-label {
        margin-top: 0;
        max-width: 80%;
        width: 80%;
      }
    }

    .available-disciplines {
      .form-checkbox-wrapper {
        .input-checkbox {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .unavailable-disciplines {
      .form-checkbox-wrapper {
        .input-checkbox {
          border: 1px solid $gray;
        }

        .checkbox-label {
          color: $gray;
        }
      }
    }

    .add-disc-container {
      width: 100%;
      height: 68px;
      padding-top: 10px;

      .add-disc-button {
        width: 100%;
        max-width: 100%;
        background: $multi-select-bg-color;
      }
    }
  }
}

.selected-disciplines-container {

  @media (max-width: 1024px) {
    padding-bottom: 35px;
  }

  &.no-border {
    border: none !important;
  }

  .selected-discipline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 0.75em 1em;
    background-color: $multi-select-container-bg;
    text-transform: uppercase;

    .add-button {
      width: 23px;
      height: 23px;
      margin-top: 5px;

      &.disabled {
        stroke: $gray;
      }
    }

    &.list-item {
      cursor: pointer;
      .discipline-detail {
        width: 85%;
        display: flex;
        flex-direction: column;

        .discipline-entry-limit {
          font-size: 13px;
          line-height: 16px;
        }
      }
      .item-label {
        display: inline-block;
        width: 85%;
      }
    }

    .discipline-title {
      font-weight: 700;
      padding-right: 5px;
      line-height: 1.2;
    }
    button {
      &:hover {
        cursor: pointer;
      }
      .edit-btn-icon svg {
        stroke: $wcra-primary-ret-secondary-brand-color;
      }
      svg {
        stroke: $preference-icon-color;
        fill: none;
      }
    }
  }

  .selected-discipline-with-classes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 0.75em 1em;
    background-color: $multi-select-container-bg;
    text-transform: uppercase;
  }
}

.discipline-with-classes-detail {
  width: 100%;

  .discipline-entry-limit {
    font-size: 13px;
    line-height: 16px;
  }

  .discipline-with-classes {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .discipline-with-classes-label {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .discipline-with-classes-hr {
    margin: 10px auto;
  }
}

.entry-fee-container.discipline-card {
  display: block;
  height: 42px;
  padding-top: 10px;

  .fee-amount {
    letter-spacing: 1px;
    font-size: 16px;
  }
}
