.count-indicator {
  display: inline-block;
  border-radius: 0.75rem;
  height: 0.9375rem;
  min-width: 0.9375rem;
  width: auto;
  line-height: 0.8125rem;
  font-size: 0.8125rem;
  text-align: center;
  letter-spacing: normal;
  font-weight: normal;

  &-my-entries {
    text-indent: -0.06125rem;
  }

  &-ret {
    color: $white;
    background-color: $dark-green;
    border: 1px solid $dark-green;

    &-selected {
      color: $black;
      background-color: $white;
      border: 1px solid $white;
    }
  }

  &-wcra {
    color: $dark-gray;
    background-color: $white;
    border: 1px solid $white;

    &-selected {
      color: $white;
      background-color: $dark-gray;
      border: 1px solid $dark-gray;
    }
  }
}
