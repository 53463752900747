.entry-partner-invite-modal {
  padding: 0 !important;

  .modal-header {
    border-bottom: 2px solid $hr-modal-color;
    padding: 1rem;
    margin-bottom: 0 !important;
    margin-top: 0 !important;

    .section-title {
      line-height: 1.2rem;
      font-size: 2rem;
    }
  }

  .modal-content {
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    padding: 0 1.25rem;
    margin-top: 0 !important;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1rem;
    max-height: calc(100vh - 220px);

    .validation-message {
      color: $red;
    }
  }

  .entry-partner-invite-modal-content {
    .description-text {
      margin: 1.5rem 0;
    }

    .partner-name-field {
      background: none;
      font-weight: 600;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;

    .clear-filter-btn {
      color: $wcra-primary-ret-secondary-brand-color;
      border: none;
      padding: 1.875rem 0;
      font-size: 1rem;
      letter-spacing: 2px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
