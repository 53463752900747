.wcra-logo {
  width: 140px;

  &.home-size {
    width: 271px;
  }
  &.home-size-xl {
    width: 371px;
  }
}

.btn-solid-gold {
  background-color: $primary-brand-color;
  color: $black;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  width: 100px;

  &:hover {
    color: $white;
  }
  &:focus,
  &:active {
    opacity: 0.9;
  }

  &.disabled {
    background-color: $gray;
    cursor: default;

    &:hover {
      color: $black;
    }
  }
}

.back-button {
  color: $wcra-primary-ret-secondary-brand-color;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 19px;
  margin-top: 60px;
  text-transform: uppercase;

  .arrow-left {
    height: 18px;
    margin-bottom: 3px;
    vertical-align: middle;
  }

  @include breakpoint($desktop) {
    margin-top: 38px;
  }
}

.back-button-no-margin {
  color: $wcra-primary-ret-secondary-brand-color;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 19px;
  text-transform: uppercase;

  .arrow-left {
    height: 18px;
    margin-bottom: 3px;
    vertical-align: middle;
  }

  margin-top: 59px;
  display: inline-block;

  @include breakpoint($desktop) {
    margin-top: 29px;
  }
}

.section-title {
  font-family: $primary-font;
  color: $primary-text-color;
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  margin-bottom: 0px;
  padding-bottom: 5px;
  padding-top: 50px;
  text-transform: uppercase;
  letter-spacing: 5px;

  @include breakpoint($desktop) {
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 70px;
    padding-top: 60px;
  }

  &.center {
    // Mobile has section title aligned to the left
    text-align: left;

    @include breakpoint($desktop) {
      padding-bottom: $section-title-padding;
      text-align: center;
    }
  }
}

.section-enter-landing-view-subtitle,
.section-registration-subtitle,
.section-preferences-subtitle,
.section-review-subtitle {
  font-family: $primary-font;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  padding-top: 24px;
  margin-bottom: 0;
}
.section-optional-label {
  float: right;
  font-size: 13px;
  color: $primary-brand-color;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 10px;
  padding-top: 24px;
}

.section-title-wide {
  width: 85% !important;
  max-width: 85%;
}

.section-optional-label-small {
  width: 15% !important;
  max-width: 15%;
}

.preferences-section-title {
  display: inline-block;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
}
.confirmation-title {
  color: $primary-brand-color;
  display: block;
}
.legal-section-title {
  display: inline-block;
  font-weight: 700;
  padding-top: 15px;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 2px;
}

.subsection-title {
  color: $gray;
  display: inline-block;
  padding-bottom: 9px;
}

.text-center-desktop {
  @include breakpoint($desktop) {
    text-align: center;
  }
}

.have-account {
  background-color: $primary-btn-bg;
  border: 2px solid $primary-btn-border;
  color: $primary-btn-text-color;
  font-size: 15px;
  font-weight: bold;
  height: 60px;
  min-height: 50px;
  letter-spacing: 1px;
  margin: 0 auto 8%;
  padding: 12px 20px;
  text-transform: none;
  width: 100%;
  display: flex;
  justify-content: center;

  &.copy {
    margin: 0 auto;
    font-size: inherit;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
  }

  @media (max-width: 767px) {
    max-width: 520px;
    width: 100%;
    font-size: 14px;
    height: 58px;
    min-height: 20px;
  }
}

.btn-main {
  background-color: $primary-btn-bg;
  border: 2px solid $primary-btn-border;
  color: $primary-btn-text-color;
  display: block;
  font-size: 20px;
  font-weight: bold;
  height: 70px;
  min-height: 70px;
  letter-spacing: 3px;
  margin: 0 auto;
  padding: 15px 30px;
  text-transform: uppercase;
  width: 320px;
  cursor: pointer;

  @media (max-width: 767px) {
    max-width: 320px;
    width: 100%;
  }

  &.link {
    padding: 22px 30px;
  }

  &:hover {
    background-color: $primary-btn-hover;
    border: 2px solid $primary-btn-border;
    color: $btn-focus-color;
  }

  &:focus {
    background-color: $primary-brand-color;
    color: $btn-focus-color;
  }

  &:hover,
  &:focus {
    transition: background-color 0.5s;
  }

  &:disabled {
    cursor: auto;
    opacity: 0.5;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  &.secondary {
    background-color: $secondary-btn-bg;
    border: 2px solid $secondary-btn-border;
    color: $white;

    &:hover {
      background-color: $secondary-btn-hover;
      border: 2px solid $secondary-btn-border;
      color: $secondary-btn-focus-color;
    }

    &:focus {
      background-color: $secondary-btn-bg;
      color: $secondary-btn-focus-color;
    }

    &:disabled {
      background-color: $secondary-btn-bg;
      border: 2px solid $disabled-button-color;
    }
  }
}

.btn-close {
  &:hover {
    cursor: pointer;
  }
}

.btn-main-end-of-page {
  bottom: 0;
  position: inherit;
  margin-top: 30px;
  width: 100%;

  @media (max-width: $phone-width) {
    padding: 0 !important;
    left: 0 !important;
    width: 100%;
  }
}

.manage-youth-register-button {
  .btn-main-end-of-page {
    @media (max-width: $phone-width) {
      bottom: 62px;
    }
  }
}
.btn-main-end-of-page {
  &.add-youth-button {
    position: relative;

    .btn-main {
      height: auto !important;
      min-height: 68px;
    }
  }
}

.btn-main.payment {
  padding: 15px 20px;
}

.btn-green {
  background-color: $green;
  border: transparent 0px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  font-family: 'Barlow';
  font-weight: bold;
  line-height: 24px;
  margin: 0 0 0 0;
  max-width: 320px;
  padding: 22px;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;

  &:disabled {
    background-color: $gray;
  }
}
.link-gold {
  background-color: transparent;
  color: $wcra-primary-ret-secondary-brand-color;
  display: block;
  font-size: 16px;
  font-family: 'Barlow';
  font-weight: normal;
  line-height: 19px;
  margin: 20px auto;
  text-transform: uppercase;

  &:hover {
    color: $wcra-primary-ret-secondary-brand-color;
    opacity: 0.95;
  }
}

a.link-text-no-decoration {
  text-decoration: none;
  display: inline-block;
}

.star {
  color: $primary-brand-color;
  margin-bottom: -5px;
  margin-right: 4px;
}

.mobile-only {
  display: inherit;

  @include breakpoint($desktop) {
    display: none;
  }
}

.desktop-only {
  display: none;

  @include breakpoint($desktop) {
    display: block;
  }
}

.grid-container {
  padding: 0 34px;

  @include breakpoint($desktop) {
    padding: 0 0.9375rem;
  }
}

.limit-width-6c {
  max-width: 600px;
}

.centered {
  margin: 0 auto;
}

.d-block {
  display: block;
}

.padbot-0 {
  padding-bottom: 0px !important;
}
.padbot-1 {
  padding-bottom: 10px;
}
.padbot-2 {
  padding-bottom: 20px;
}
.padbot-3 {
  padding-bottom: 30px;
}
.padbot-4 {
  padding-bottom: 40px;
}
.padbot-5 {
  padding-bottom: 50px;
}
.padtop-0 {
  padding-top: 0px !important;
}
.padtop-1 {
  padding-top: 10px;
}
.padtop-2 {
  padding-top: 20px;
}
.padtop-3 {
  padding-top: 30px;
}
.padtop-4 {
  padding-top: 40px;
}
.padtop-5 {
  padding-top: 50px;
}

.padlef-3 {
  padding-left: 30px;
}

.marbot-0 {
  margin-bottom: 0px !important;
}
.marbot-1 {
  margin-bottom: 10px !important;
}
.marbot-15 {
  margin-top: 15px !important;
}
.marbot-2 {
  margin-bottom: 20px !important;
}
.marbot-5 {
  margin-bottom: 50px !important;
}
.martop-0 {
  margin-top: 0 !important;
}
.martop-1 {
  margin-top: 10px !important;
}
.martop-2 {
  margin-top: 20px !important;
}
.martop-3 {
  margin-top: 30px !important;
}
.martop-4 {
  margin-top: 40px !important;
}
.martop-5 {
  margin-top: 50px !important;
}

.h-auto {
  height: auto !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.first-hr-no-margin > hr:first-child {
  margin-top: 10px !important;
}

.martop-05 {
  margin-top: 5px !important;
}

.martop-15 {
  margin-top: 15px !important;
}

.options-list {
  hr {
    background-color: $medium-gray;
    color: $medium-gray;
    margin: 0px;

    @include breakpoint($desktop) {
      color: $dark-gray;
    }

    &:first-child {
      margin-top: 17px;
    }
    &:last-child {
      margin-bottom: 17px;
    }
  }

  a {
    color: $dashboard-options-color;
    display: block;
    padding: 17px 5px;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      color: $primary-brand-color;
      text-decoration: none;
      -webkit-transition: color $transition_speed_mid;
      transition: color $transition_speed_mid;
    }

    svg {
      color: $primary-brand-color;
    }
  }
}

.athletes-list {
  .athlete-container {
    padding-top: 1em;

    .athlete-name {
      font-family: 'Barlow', sans-serif;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: normal;
      line-height: 24px;
      text-transform: uppercase;
    }

    > a {
      text-transform: uppercase;
    }
  }
}

.color-gold {
  color: $primary-brand-color;
}
.color-green {
  color: $pref-green-color;
}

.input-chosen {
  font-family: $primary-font;
  font-size: 20px;
  text-transform: uppercase;
}

.event-rank {
  font-family: $primary-font;
  font-size: 17px;
  text-transform: uppercase;
  display: block;
}

.event-image {
  width: 100%;
  display: block;
  padding-bottom: 25px;
  img {
    width: 100%;
  }
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-n1 {
  margin-left: -5px;
}

.back-button {
  color: $wcra-primary-ret-secondary-brand-color;
  display: block;
}

.selectable-area {
  padding: 20px 0;
  width: 100%;

  &:hover,
  .label:hover {
    cursor: pointer;

    > .label {
      color: $primary-brand-color;
    }
  }
}
.selectable-secondary,
.add-teammate {
  color: $primary-brand-color;
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: $disabled-opacity;
    color: $gray;
    cursor: default;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.remove {
  color: $primary-brand-color;
  float: right;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

.grid-container {
  padding: 0 24px;

  @media #{$narrow-phone-bigger-width} {
    padding: 0 $container-mobile-padding;
  }
}

.grid-container-minimal {
  @extend .grid-container;
  padding: 0 5px;
}

.icon-checkmark {
  height: 30px;
  margin-bottom: -5px;
  width: 30px;

  @include breakpoint($desktop) {
    height: 50px;
    margin-bottom: -10px;
    width: 50px;
  }
}

.segment-option {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark {
    background-color: $primary-brand-color;
    border-color: $black;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $primary-bg-color;
    border-radius: 50%;
    border: 1px solid $primary-brand-color;

    &:after {
      content: '';
      position: absolute;
      display: none;
      top: 7px;
      left: 7px;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: $black;
    }
  }

  .segment-label {
    margin-left: 40px;
  }
}

.segment-dropdown-value {
  &.date {
    font-size: 0.8em;
    display: block;
  }
}

.input-checkbox {
  border: 2px solid $checkbox-inactive-border;
  position: relative;
  display: block;
  width: 30px;
  min-width: 30px;
  height: 30px;

  &.disabled {
    border-color: $checkbox-disabled-border;
  }
  &:hover:not(.disabled) {
    cursor: pointer;
  }

  .checkmark {
    color: $checkmark-color;
    height: 22px;
    left: 2px;
    top: 3px;
    display: none;
    position: absolute;
    width: 22px;
  }

  &.checked {
    &:not(.disabled) {
      background-color: $wcra-primary-ret-secondary-brand-color;
      border: 2px solid $wcra-primary-ret-secondary-brand-color;
      .checkmark {
        display: block;
        top: 3px;
      }
    }
    &.disabled {
      background-color: $checkbox-disabled-border;
      border: 2px solid $checkbox-disabled-border;
      .checkmark {
        display: block;
        top: 3px;
      }
    }
  }
}

.mobile-title {
  color: $primary-text-color;
  display: block;
  font-family: $primary-font;
  font-size: 32px;
  font-weight: 600;
  padding: 0 0 15px 0;
  letter-spacing: 4.8px;
  line-height: 38px;
  text-transform: uppercase;

  @include breakpoint($desktop) {
    display: none;
  }
}

.back-button.mobile-only {
  margin-top: 0;
}

.mobile-only {
  .section-title {
    padding-top: 0;
  }

  .back-button,
  .back-button-no-margin {
    margin-top: 0;
  }
}

.mobile-title-wrapper {
  display: flex;
  justify-content: space-between;

  @include breakpoint($desktop) {
    display: none;
  }

  .section-title {
    padding: 0;
    line-height: 1em;
  }

  .back-button-no-margin {
    height: fit-content;
    margin-top: auto;
  }
}

.theme-content-container {
  width: 100%;
  margin: 36px auto 0;
}

.stacked-content-container {
  width: 100%;
  margin: 15px auto 0;
}

.nomination-confirmation {
  .btn-main.link {
    text-decoration: none;
  }
}

.no-border {
  border: 0 !important;
}

.privacy-policy,
.terms-of-use {
  @media (max-width: $phone-width) {
    a {
      word-break: break-all;
    }
  }
}

.gender-container {
  display: grid;
  width: 100%;
  align-items: center;

  .gender-label {
    display: inline-block;
    padding-right: 5px;
    font-size: 14px;
    padding-bottom: 3px;
    vertical-align: text-bottom;
  }

  .gender-option {
    display: inline;
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 5px;
  }

  input {
    visibility: hidden;
    position: absolute;
  }

  label {
    font-size: 18px;
    text-transform: uppercase;
    color: $primary-label-color;
    margin-right: 0;
    margin-left: 3px;
    font-weight: 300;
  }

  label:before {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    content: ' ';
    display: inline-block;
    vertical-align: text-bottom;
    border: 2px solid $primary-outline-color;
  }

  input + label:before {
    border-radius: 50%;
  }

  input:checked + label:before {
    border: 2px solid $secondary-brand-color;
  }

  input:checked + label:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background-color: $secondary-brand-color;
    border-radius: 24px;
  }
}

.nomination-confirmation.entry-confirmation.theme-content-container {
  margin-bottom: 0;
}

.card-content .section-preferences-subtitle {
  font-size: 1.5rem;
  padding-bottom: 10px;
  padding-top: 0;
}
.citizen-container {
  display: inline-block;
  width: 100%;
  align-items: center;
  padding-bottom: 8px;

  .citizen-label {
    display: inline-block;
    padding-right: 5px;
    font-size: 14px;
    padding-bottom: 3px;
    vertical-align: text-bottom;
  }

  .citizen-option {
    display: inline;
  }

  input {
    visibility: hidden;
    position: absolute;
  }

  label {
    font-size: 18px;
    text-transform: uppercase;
    color: $primary-label-color;
    margin-right: 0;
    margin-left: 3px;
    font-weight: 300;
  }

  label:before {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    content: ' ';
    display: inline-block;
    vertical-align: text-bottom;
    border: 2px solid $primary-outline-color;
  }

  input + label:before {
    border-radius: 50%;
  }

  input:checked + label:before {
    border: 2px solid $secondary-brand-color;
  }

  input:checked + label:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background-color: $secondary-brand-color;
    border-radius: 24px;
  }
}

.tooltip-help-icon {
  fill: $secondary-brand-color;
}

.black-tooltip {
  background-color: $black;
  font-size: 15px;
  max-width: 350px;
  font-family: $primary-font;
}

.black-tooltip-arrow {
  color: $black;
}

.black-popper {
  z-index: 10002;
}

.attention-text {
  color: $redError;
  padding-left: 0.5em;
}

.reg-optional {
    float: right;
    margin-top: -30px;
    color: $optional-label;
}
.center-img {
  margin: 0 auto;
  display: block;
}

.p-md {
  padding: 6%;
}

.p-sm {
  padding: 2%;
}

.display-inline {
  display: inline;
}

.font-bold {
  font-weight: bold;
}