// Edit Profile
form.profile-edit,
form.email-edit,
form.password-edit {
  // Address
  .icon-map-pin {
    vertical-align: bottom;
  }

  .street,
  .street2,
  .city,
  .state,
  .zipcode {
    padding: 15px 0 25px;
  }

  .underline-field {
    border-bottom: 2px solid #7e7e7e;
  }

  // Photo Upload
  .photo-upload-wrapper {
    position: relative;

    &:hover {
      cursor: pointer;

      > .photo-placeholder-wrapper,
      .photo-current {
        opacity: 0.8;
        -webkit-transition: opacity $transition_speed_long;
        transition: opacity $transition_speed_long;
      }
    }

    button {
      color: $medium-gray2;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      text-transform: uppercase;
      width: 100%;

      &:focus,
      &:active {
        background: transparent;
      }

      &:hover {
        cursor: pointer;
      }
    }
    input[type='file'] {
      display: none;
    }
  }
  .photo-placeholder-wrapper,
  .photo-current {
    bottom: 0;
    height: 41px;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 41px;
  }
  .photo-placeholder-wrapper {
    background-color: $gray;
    border-radius: 50%;
  }
  .photo-placeholder {
    bottom: 0;
    height: 21px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    stroke: $dark-gray;
    top: 0;
    width: 21px;
  }
  .photo-current {
    background-size: cover;
    background-position: center top;
    border-radius: 50%;
  }

  .horse-input {
    text-overflow: ellipsis;
  }
}

form.email-edit {
  .form-group,
  .form-group input {
    width: 100%;
  }
}

// View Profile
.profile-view {
  letter-spacing: 3px;
  text-transform: uppercase;

  .profile-photo {
    background-color: $secondary-bg-color;
    background-size: cover;
    background-position: center top;
    border: 2px solid $gray;
    border-radius: 50%;
    height: 90px;
    margin: 0 auto 24px;
    width: 90px;

    @include breakpoint(medium) {
      height: 125px;
      width: 125px;
    }

    @include breakpoint($desktop) {
      height: 180px;
      margin: 0 auto 20px;
      width: 180px;
    }

    &.default-photo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
    }
  }

  .profile-name {
    color: $profile-name-color;
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
    text-align: center;

    @media #{$narrow-phone-width} {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .profile-location,
  .profile-discipline-label {
    color: $profile-location-color;
    font-size: 16px;
    line-height: 19px;
    text-align: left;

    @media #{$narrow-phone-width} {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .profile-location {
    padding-top: 12px;
    text-align: center;

    @include breakpoint($desktop) {
      margin-bottom: 20px;
    }
  }

  .profile-discipline {
    color: $profile-discipline-color;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;

    @media #{$narrow-phone-width} {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.profile-birthdate-label {
  text-align: left;
}

.athlete-stats-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .large-4 {
    width: 30%;
    border-bottom: 2px solid $dark-gray2;
    padding-bottom: 17px;
  }
}

#PlacesAutocomplete__autocomplete-container {
  z-index: 999;
}

.classification {
  .classification-title {
    margin-top: 30px;
    color: $white;
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    text-align: left;

    @media #{$narrow-phone-width} {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .classification-content {
    margin-top: 10px;

    label {
      text-align: left;
      margin-bottom: 5px;
    }
  }

  .contestant-disciplines-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $gray;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
  }

  .contestant-disciplines-values {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
  }

  .no-classification-message{
    margin-top: 1.5rem;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: initial;
    padding-left: 15px;
    padding-right: 15px;
  }

  .bottom-classification-message{
    margin-top: 1.5rem;
    font-family: Barlow;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: initial;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.social-icons-wrp {
  text-align: center;
  margin: 0 auto;
  padding-top: 24px;

  a {
    color: white;
  }
  .social-icon-profile {
    border-radius: 25px;
    height: 45px;
    width: 45px;
    padding: 10px;
    margin: 17px;

    &.facebook {
      background: #3b5998;
    }
    &.twitter {
      background: #1ea1f2;
    }
    &.instagram {
      background: #3896f0;
    }
  }
}
