.review-view {
  margin-bottom: 0;

  .label-review {
    font-size: 16px;
    line-height: 30px;
    text-transform: uppercase;
  }

  .selected-discipline {
    background-color: $review-selected-discipline-bg-color;
    height: auto;
    display: flex;
    margin-bottom: 0;
    padding: 3px 12px;
    cursor: pointer;

    span {
      font-size: 13px;
      font-weight: 200;
      line-height: 0;
    }

    .entry-description,
    .nomination-description,
    .partner-label {
      display: block;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 1px;
      margin-top: 10px;
    }

    .nominations {
      margin-top: 10px;
    }

    .horse-names {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .discipline-review-card {
      width: 100%;

      button {
        margin-top: 1rem;
      }
    }
    .discipline-review-card-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .discipline-label {
      padding-top: 16px;

      .and-partner-fees {
        font-weight: 700;
        font-size: inherit;
      }
    }

    .discipline-icons {
      display: block;
      width: 100%;
      height: 45px;
      background: $review-selected-discipline-bg-color;
      padding-top: 10px;

      .edit-btn-label {
        color: $secondary-brand-color;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 1px;
        position: absolute;
        padding-top: 10px;
        padding-left: 5px;
      }
    }
  }

  .selected-discipline-with-classes {
    background-color: $review-selected-discipline-bg-color;
    height: auto;
    display: flex;
    margin-bottom: 0;
    padding: 3px 12px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);

    span {
      font-size: 13px;
      font-weight: 200;
      line-height: 0;
    }

    .entry-description,
    .nomination-description,
    .partner-label {
      display: block;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 1px;
    }

    .nominations {
      margin-top: 10px;
    }

    .horse-names {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .discipline-review-card {
      width: 100%;

      button {
        margin-top: 1rem;
      }
    }
    .discipline-review-card-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .discipline-label {
      padding-top: 16px;
      font-weight: bold;

      .and-partner-fees {
        font-weight: 700;
        font-size: inherit;
      }

      .classnames {
        font-size: 14px;
        font-weight: normal;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .discipline-icons {
      display: block;
      width: 100%;
      height: 45px;
      background: $review-selected-discipline-bg-color;
      padding-top: 10px;

      .edit-btn-label {
        color: $secondary-brand-color;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 1px;
        position: absolute;
        padding-top: 10px;
        padding-left: 5px;
      }

      svg {
        color: $secondary-brand-color;
      }
    }
  }

  .prices-section {
    span {
      display: inline-block;
      float: right;
      color: $primary-brand-color;
      margin-top: -25px;
    }
  }

  .form-link-container {
    padding-bottom: 50px;

    & > button {
      color: $wcra-primary-ret-secondary-brand-color;
    }
  }
}
