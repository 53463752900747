.discipline-entered {
  .confirmation-title {
    color: $white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2.7px;
    line-height: 22px;
    text-transform: uppercase;
    padding-bottom: 5px;

    &.disciplines-entered {
      font-size: 18px;
      letter-spacing: 2.7px;
      padding-top: 35px;
    }
  }
  .confirmation-text {
    font-size: 16px;
    letter-spacing: 1.05px;
  }
  .confirmation-discipline {
    padding-top: 15px;
    font-weight: bold;
  }

  span {
    &.entry-description {
      display: block;
      span {
        margin-top: 0.5rem;
      }
      .preferences-and-outs-container{
        margin-top: 0.5px;
      }
    }
  }

  .entry-description {
    margin-top: 10px;
  }

}
.confirmation-partner-container {
  font-size: 16px;
  letter-spacing: 1px;
}
