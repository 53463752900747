.drawer-closed-performances {
  margin-top: 25px;
  color: $back-button-color;
  cursor: pointer;
  font-size: 17px;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
  padding-right: 0px;

  .icon {
    height: 22px;
    margin-bottom: 3px;
    vertical-align: middle;
  }
  display: inline-block;
}
.competition-list-select {
  margin-top: 10px;

  .date-item {
    margin-top: 20px;
    border: 2px solid $primary-brand-color;
    background-color: $preference-card-bg-color;
    display: flex;
    cursor: pointer;
    position: relative;
    padding: 15px;

    &.selected {
      border-color: $event-pref-selected-color;
      .date-item-right {
        .selected-or-not {
          opacity: 1;
        }
      }
      &:before {
        width: 20px;
        height: 20px;
        top: -11px;
        left: -11px;
        position: absolute;
        border-radius: 20px;
      }
      &-preference {
        &:before {
          content: '';
          @include pref-selected-pseduo-element;
        }
      }
      &-out {
        &:before {
          content: '';
          background: url('../img/icons/x.svg');
          background-position: center;
          background-size: 8px;
          background-repeat: no-repeat;
          @include out-selected-pseduo-element;
        }
      }
    }

    &.disabled {
      border-color: $disabled-pref-color;
      opacity: $disabled-opacity;
      .date-item-right {
        .selected-or-not {
          opacity: 1;
        }
      }

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        top: -11px;
        left: -11px;
        position: absolute;
        border-radius: 20px;
        @include pref-disabled-pseduo-element;
      }
    }

    .date-item-center {
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .date-item-left {
      max-width: 620px;
      margin-right: auto;

      .performance-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        font-size: 12px;
        text-transform: uppercase;

        .calendar-icon-margin {
          color: $back-button-color;
          font-size: 16px;
          text-transform: uppercase;

          .calendar-icon {
            height: 18px;
            margin-bottom: 3px;
            margin-right: 10px;
            vertical-align: middle;
          }

          display: inline-block;
        }

        .info-container {
          width: 100%;
          display: inline-block;
          flex-basis: auto;

          .performance-name {
            display: flex;
            font-weight: bold;
            text-transform: uppercase;
          }

          .performance-date {
            display: inline-block;
          }
        }
      }
    }

    .date-item-right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: right;

      .open-label {
        display: block;
        color: $green;
        text-transform: uppercase;
        font-size: 12px;
      }

      .closed-label {
        display: block;
        color: $red;
        text-transform: uppercase;
        font-size: 12px;
      }

      .selected-or-not {
        display: block;
        opacity: 0.6;
        font-size: 16px;
      }
    }
  }
}

.performance-list-select {
  margin-top: 10px;

  .date-item {
    margin-top: 20px;
    border: 2px solid $primary-brand-color;
    background-color: transparent;
    display: flex;
    cursor: pointer;
    position: relative;
    padding: 15px;

    &.selected {
      border-color: $event-pref-selected-color;
      .date-item-right {
        .open-label {
          font-weight: bold;
        }
        .selected-or-not {
          opacity: 1;
        }
      }
      &:before {
        width: 20px;
        height: 20px;
        top: -11px;
        left: -11px;
        position: absolute;
        border-radius: 20px;
      }
      &:before {
        content: '';
        @include pref-selected-pseduo-element;
      }
    }

    &.disabled {
      border-color: $disabled-pref-color;
      cursor: default;

      .date-item-right {
        .selected-or-not {
          opacity: 1;
        }
      }
    }

    .date-item-center {
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .date-item-left {
      max-width: 620px;
      margin-right: auto;

      .performance-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        font-size: 12px;
        text-transform: uppercase;

        .calendar-icon-margin {
          color: $back-button-color;
          font-size: 16px;
          text-transform: uppercase;

          .calendar-icon {
            height: 18px;
            margin-bottom: 3px;
            margin-right: 10px;
            vertical-align: middle;
          }

          display: inline-block;
        }

        .info-container {
          width: 100%;
          display: inline-block;
          flex-basis: auto;

          .performance-name {
            display: flex;
            font-weight: bold;
            text-transform: uppercase;
          }

          .performance-date {
            display: inline-block;
          }
        }
      }
    }

    .date-item-right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: right;

      .open-label {
        display: block;
        color: $green;
        text-transform: uppercase;
        font-size: 12px;
      }

      .closed-label {
        display: block;
        color: $red;
        text-transform: uppercase;
        font-size: 12px;
      }

      .selected-or-not {
        display: block;
        opacity: 0.6;
        font-size: 16px;
      }
    }
  }
}
