.nominate-totals {
  .price-label,
  .price {
    font-size: 16px;
    line-height: 29px;
  }

  .price {
    text-transform: uppercase;
  }

  .price-label {
    text-transform: capitalize;
  }

  .price,
  .price-total {
    color: $primary-brand-color;
    text-align: right;
  }

  .price-total-label,
  .price-total {
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .price-total-label {
    padding-bottom: 15px;
  }
}

.additional-discipline {
  border-left: 2px solid $gray;
  font-size: 18px;
  line-height: 24px;
  margin-top: 5px;
  padding-left: 10px;
  text-transform: uppercase;
}

.default-segment-label {
  color: $white;
  float: right;
  margin-right: 10px;
  font-size: 0.8em;
  text-transform: uppercase;
}

.modal-close-bottom {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 25px;
  cursor: pointer;
}

.modal-confirm-bottom {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 65px;
  padding: 12px;
  width: 80%;
}

.nomination-confirmation {
  font-size: 16px;
  line-height: 19px;

  .confirmation-label {
    color: $gray;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .confirmation-image {
    img {
      width: 100%;
      padding-bottom: 15px;
    }
  }

  .confirmation-event-name {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 3px;
    text-transform: uppercase;
  }

  .confirmation-event-date {
    padding-bottom: 3px;
  }

  .confirmation-left {
    display: inline-block;
    width: 60%;
  }

  .confirmation-right {
    display: inline-block;
    text-align: right;
    width: 40%;
  }
}

.form-disabled {
  .hide-on-disabled {
    display: none;
  }
}

.discount-desc {
  padding-left: 15px;
  display: block;
  color: $white;
}

.discount-price {
  font-size: 0.9em;
  display: block;
  color: $primary-brand-color;
}

.coupon-code-valid-note {
  color: #8ce04a;
  text-transform: uppercase;
}

.nominations-event-results-container {
  #event-results {
    .new-list{
      overflow-y: auto;
      height: 65vh;
      padding-bottom: 20px;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .event-result {
      .event-entry-deadline {
        display: none;
      }
    }
  }

  .option-margin-bottom {
    position: relative;
    margin-bottom: 10px;
  }

  .option-description {
    font-size: 10px;
    margin-top: 1px;
    position: absolute;
  }

  .search-and-filters{
    display: flex;
    flex-direction: row;
    align-items: center;
    .search-container{
      flex: 1;
    }
    .filter-icon-container{
      height: 58px;
      width: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-brand-color;
      .filter-icon{
        width: 40px;
        height: 40px;
        color: $black
      }
    }

  }
}

.nominations-filter-container {
  background-color: $event-search-card-bg-color;
  padding: 20px;

  .MuiInputBase-input {
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    color: $white;
  }

  .MuiFormLabel-root, .MuiFormLabel-root.Mui-focused {
    color: $gray;
    font-family: "Barlow", san-serif;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    transform: translate(0, 1.5px) scale(1);
    letter-spacing: 1.4px;
    position: relative;
    text-transform: uppercase;
  }
  .MuiInputBase-input {
    padding: 6px 7px 6px 0px;

    &::placeholder {
      color: $dark-gray2 !important;
      font-size: 20px;
      font-family: "Barlow", san-serif;
      padding-left: 0;
      font-weight: 500;
      opacity: 1;
    }
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid $dark-gray2;
  }

  .MuiInput-underline:after {
    display: none;
  }

  .react-datepicker-wrapper::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid $dark-gray2;
    pointer-events: none;
  }

  .Select {
    padding-top: 12px;

    .Select-option.is-focused {
      color: $white;
    }

    .Select-option.is-disabled {
      color: #333333;
      cursor: not-allowed;
    }

    .Select-control {
      border-radius: 0;

      &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 2px solid $dark-gray2;
        pointer-events: none;
      }
    }

    .Select-placeholder {
      color: $dark-gray2;
    }
  }

  .buttons-container {
    display: flex;
    gap: 15px;
    flex-direction: row;
    padding-top: 17px;
  }

  .btn-main {
    padding: 10px 10px;
    font-size: 15px;
    width: 200px;
    height: 60px;
    min-height: 60px;
  }

  .date-field {
    background: url('../img/icons/calendar.svg') no-repeat right;
    background-size: 20px;
  }

  .react-datepicker {
    &__day-names{
      text-align: left;
      margin-left: 6px;
      margin-bottom: 0.166rem;
    }
    &__day-name,&__day{
      width: 36px;
      height: 36px;
      border-radius: 5px;
      background: $dark-gray;
      text-decoration: none;
      font-weight: 500;
      line-height: 24px;
      font-size: 16px;
      color: $white;
    }
    &__day--outside-month{
       opacity: 0.5;
    }
    &__current-month{
      margin-bottom: 12px;
    }
    &__navigation--previous{
      background: url('../img/icons/arrow-left.svg') no-repeat;
      width: 24px;
      height: 24px;
      border: none;
    }
    &__navigation--next{
      background: url('../img/icons/arrow-right.svg') no-repeat;
      width: 24px;
      height: 24px;
      border: none;
    }
    &__day--in-selecting-range{
      background-color: $primary-brand-color;
      border-radius: 5px;
      color: $black;
    }
  }
}
.filters-results-container{
  .filters-header{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .title{
      text-transform: uppercase;
      font-size: 14px;
      color: $gray;
    }
    .back-button{
      margin-top: 0;
    }
  }
  .filter-results{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
  }
  .filter-result{
    display: flex;
    gap: 3px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2px;
    font-size: 14px;
    text-transform: uppercase;
    .filter-result-title{
      color: $primary-brand-color;
    }
  }
}


.nominations-right-column {
  text-align: center;

  @include breakpoint($desktop) {
    text-align: right;
    padding-top: 50px;
    padding-left: 60px;
  }

  button {
    margin-top: 10px;
  }

  @media (max-width: 1023px) {
    button {
      margin-bottom: 15px;
    }
  }
}

.selected-teammate {
  width: 300px;
  margin: auto;
  padding-bottom: 25px;
}

.remove-preference {
  height: 25px;
  margin-top: 2px;
  width: 25px;
  color: $remove-preference-color;
  float: right;

  &:hover {
    cursor: pointer;
  }
}

.pref-date,
.pref-date-out {
  display: inline-block;
  padding-left: 15px;
}

.react-datepicker-popper {
  z-index: 1000;
}

.segments-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 165px;
  width: 300px;
  padding: 15px;

  @include breakpoint($desktop) {
    width: 350px;
    padding: 0px;
  }

  .segments-visible {
    display: table;
    width: 100%;
    padding-bottom: 15px;
    text-align: left;

    .segment-name {
      color: $gray;
      text-transform: uppercase;
      padding-left: 10px;
      display: block;
      font-size: 0.9em;
    }

    .segment-date {
      text-transform: uppercase;
      padding-left: 10px;
      display: block;
    }

    .input-checkbox {
      display: table-cell;
      height: 26px;
      width: 42px;

      .checkmark {
        height: 32px;
        left: 1px;
        width: 35px;
        top: 5px;
      }
    }

    label {
      display: table-cell;
      padding-left: 10px;
    }
  }
}

.coupon-code-wrapper {
  position: relative;

  .btn-coupon-code {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.info-review-performances-review {
  display: inline-block;
  color: $back-button-color;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 22px;
  text-transform: uppercase;
  padding-right: 10px;

  .icon {
    height: 22px;
    margin-bottom: 3px;
    vertical-align: middle;
  }
}

.review-performances-text {
  margin-top: 30px;
}

.closed-noms-section {
  > h3 {
    font-family: $primary-font;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 24px;
    text-transform: uppercase;
  }

  > span {
    font-family: $primary-font;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 19px;
  }
}
