.youth-registration-status {
  .add-another-youth-athlete-link {
    & > button {
      text-transform: uppercase;
      color: $wcra-primary-ret-secondary-brand-color;
    }

    &:focus {
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
