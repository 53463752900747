.entry-event-discipline {
  background-size: cover;
  max-height: 250vh;
  background-image: url('../../assets/img/background-images/bg-blur-white.png');
  .modal-header {
    border-bottom: 2px solid $hr-modal-color;
    padding: 15px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    .section-className {
      line-height: 1.2;
      font-size: 16px !important;
      margin-top: 5px;
    }
  }
  .section-title {
    font-size: 50px;
    line-height: 60px;
    width: 240px;
  }

  .discipline-title {
    line-height: 1.2;
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
  }

  .display-user-steps {
    color: $gray;
    flex-shrink: 0;
  }

  .top-heading {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
  }

  .user-steps {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
  
  .passed-step {
    width: 100%;
    height: 2px;
    margin-left: 2px;
    margin-right: 2px;
    background: $dark-green;
  }

  .not-passed-step {
    width: 100%;
    height: 2px;
    margin-left: 2px;
    margin-right: 2px;
    background: $gray;
  }

  .modal-content {
    overflow-y: auto;
    margin-top: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    max-height: calc(100vh - 220px) !important;
  }

  .entry-fee-container {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;

    .fee-amount {
      color: $primary-brand-color;
    }
  }

  .entry-discipline-dateAndTime {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 700;
  }

  .entry-discipline-numberOfEntries-container {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 700;
    justify-content: space-between;
    display: flex;

    .numberOfEntries-arrow-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .numberOfEntries-arrow {
        display: inline-block;
        color: $entry-discipline-modal-arrow-active;
      }

      .disabled {
        color: $entry-discipline-modal-arrow-disabled;
      }

      .numberOfEntries {
        display: inline-block;
        margin: auto 0.5rem;
      }
    }
  }
  .segment-check-list .segments-visible {
    margin-bottom: 10px;
  }
  .entry-discipline-numberOfNominations-container {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
    justify-content: space-between;
    display: flex;
    margin-bottom: 1.5rem;

    .numberOfNominations-arrow-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .numberOfNominations-arrow {
        display: inline-block;
        color: $entry-discipline-modal-arrow-active;
      }

      .disabled {
        color: $entry-discipline-modal-arrow-disabled;
      }

      .numberOfNominations {
        display: inline-block;
        margin: auto 0.5rem;
      }
    }

    .entry-discipline-numberOfNominations-title {
      margin-bottom: 0;
    }
  }

  .entry-disciplines-modal-content {
    box-sizing: content-box;
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    button {
      text-transform: uppercase;
    }

    .btn-main {
      padding: 0;
      margin: 0px auto;
    }

    .clear-filter-btn {
      color: $wcra-primary-ret-secondary-brand-color;
      border: none;
      padding: 30px 0;
      font-size: 16px;
      letter-spacing: 2px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .partner-container {
    padding-top: 50px;

    .partner-section-title {
      display: inline-block;
      padding-bottom: 15px;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 2px;
    }

    .optional-label {
      float: right;
      font-size: 13px;
      color: $primary-brand-color;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  .same-partner-container {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;

    .same-partner-section-title {
      display: inline-block;
      letter-spacing: 1px;
    }
  }

  .partner-async-container {
    .partner-number {
      letter-spacing: 1px;
      font-weight: bold;
    }

    input {
      width: 100%;
      margin-bottom: 10px;

      &:focus {
        outline: none;
      }
    }

    hr {
      margin-top: 10px;
      margin-bottom: 21px;
      &.error {
        background-color: $red;
      }
    }

    .validation-partner-message {
      color: $red;
    }

    label {
      padding-left: 10px;
    }

    .form-checkbox-wrapper {
      span,
      label {
        display: inline-block;
      }
    }

    .partner-section-title {
      display: inline-block;
      padding-bottom: 15px;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 2px;
    }

    .optional-label {
      float: right;
      font-size: 13px;
      color: $primary-brand-color;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .add-partner-description {
      letter-spacing: 1px;
    }

    .new-partner-explanation {
      margin-bottom: 21px;
    }

    .partner-fees-description {
      font-size: 16px;
      letter-spacing: 1px;
      color: $dark-gray2;
      top: -10px;
    }

    .partner-lock-icon {
      color: $primary-brand-color;
    }

    .Select-input {
      padding-left: 0;
    }

    .Select-placeholder {
      color: $entry-partner-search-place-holder;
    }

    .Select-menu-outer {
      background: $multi-select-item-bg-color;
      @include partner-options-shadow;

      .Select-option {
        background: $multi-select-item-bg-color;

        &:hover,
        &:focus,
        &:active {
          background: $primary-brand-color;
          color: $entry-partner-search-option-font-color;
        }
      }
    }
  }

  .partner-athlete-option {
    display: flex;
    flex-direction: column;

    .partner-option-location {
      font-size: 1rem;
      font-weight: 100;
    }
  }
}
.show {
  display: block;
}
.hide {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .main-content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 8px;
  }
  
  .discipline-title {
    width: 240px;
  }
}
