.entry-performance-card {
  .date-item {
    border: 2px solid $date-item-color;
    padding: 0;
    width: 100%;

    .date-item-right {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-transform: uppercase;
      position: relative;
      .time {
        width: 100%;
        font-weight: bold;
        font-size: 16px;
      }

      .preference-description {
        line-height: 25px;
        display: flex;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .text-container{
           width: 100%;
          &.selected {
            text-align: left;
          }
        }

        .preference-name{
          line-height: 15px;
          width: auto;
        }
        .preference-bullet {
          border: 2px solid $primary-brand-color;
          border-radius: 50%;
          box-sizing: content-box;
          padding: 3px;
          font-size: 20px;
          width: 26px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .preference-bullet.not {
          border: 2px solid $entry-cant-compete-border-color;
          border-radius: 0;
          padding: 2px 5px;
          letter-spacing: 2.4px;
          top: 15px;
          width: auto;
          height: auto;
        }
      }

      span {
        font-size: 14px;
      }
    }

    .date-item-left {
      width: 25%;
      border-right: 2px solid $date-item-color;
      font-weight: bold;

      .month {
        background: $date-item-color;
        font-family: $primary-font;
        color: $dark-gray;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      }
      .week-day {
        height: 60px;
        line-height: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        span:nth-of-type(1) {
          display: block;
          font-size: 20px;
          padding-bottom: 4px;
        }

        span:nth-of-type(2) {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .entries-counter {
    background-color: $entries-count-bg-color;
    width: 100%;
  }

  .date-item.selected.selected-preference,
  .date-item.selected.selected-out {
    border: 2px solid $preference-card-opt-out-color;

    .date-item-left {
      border-right: 2px solid $preference-card-opt-out-color;
    }

    .month {
      background-color: $preference-card-opt-out-color;
      color: $preference-card-opt-out-date-color;
    }
  }

  .date-item.selected.selected-preference {
    border: 2px solid $preference-card-opt-in-color;

    .date-item-left {
      border-right: 2px solid $preference-card-opt-in-color;
      .month {
        background: $preference-card-opt-in-color;
        color: $preference-card-opt-out-date-color;
      }
    }
  }
}
