.card-data-section {
  background: $white;

  .add-card-button-wrp {
    width: 100%;
    margin: 20px auto;
  }

  form {
    margin-top: 40px;

    label {
      display: inline-block;
      padding-right: 10px;
      padding-left: 5px;
    }

    .pay-button-wrp {
      width: 100%;
      margin: 10px auto;
    }

    .field-row {
      padding: 15px;
      border-bottom: 1px solid #ccc;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;

      .icon {
        color: $primary-brand-color;
      }
    }

    .cvc-column {
      border-left: 1px solid #ccc;
    }

    .card-input {
      color: $gray;
    }

    .card-first-name {
      width: 60%;
    }
    .card-last-name {
      width: 60%;
    }
    .card-number {
      width: 80%;
    }
    .card-expiry-date {
      width: 50%;
    }
    .card-cvc {
      width: 70%;
    }
    .card-zip-code {
      width: 60%;
    }
  }

  .saved-cards-section {
    color: $gray;

    h2 {
      padding: 15px;
      font-size: 1.9em;
    }

    .btn-card-select-pay {
      padding: 15px 40px;
    }

    .card-info-wrp {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;
    }

    .card-description {
      padding-left: 10px;
      color: $gray;
      display: inline-block;
      width: 87%;
    }

    .brand-card {
      margin-left: 15px;
      width: 50px;
      height: 50px;
      display: inline-block;

      &.mastercard {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/MasterCard.png');
      }

      &.americanexpress {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/AmericanExpress.png');
      }

      &.dinersclub {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/DinersClub.png');
      }

      &.discover {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/Discover.png');
      }

      &.jcb {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/JCB.png');
      }

      &.unionpay {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/UnionPay.png');
      }

      &.visa {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../assets/img/cards/Visa.png');
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      border-top: 1px solid #ccc;

      li {
        padding: 25px 15px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        @media (max-width: 372px) {
          padding: 25px 0;
        }
      }
    }
  }
}

.user-settings.saved-cards-section {
  ul {
    margin: 0;
  }
  li {
    list-style: none;
    border: 1px solid white;
    text-align: center;
    border-radius: 4px;
    margin: 12px 0;
    overflow: hidden;

    .content {
      display: inline-block;
      padding: 24px 0;
    }

    .delete-icon-container {
      background: #ededed;
      width: 48px;
      height: 73px;
      float: right;
      border-left: 1px solid white;

      .delete-icon {
        margin-top: 21px;
        color: $secondary-brand-color;
        padding: 4px;
        width: 32px;
        height: 32px;
      }
    }
  }
}

.card-wrp {
  width: 184px;
  margin: 30px auto 0 auto;

  .card-bg {
    background-image: url('../../assets/img/card.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 184px;
    height: 122px;

    .card-brand {
      font-size: 0.8em;
      position: absolute;
      top: 20px;
      right: 23px;
    }
    .card-number {
      display: block;
      text-align: center;
      font-size: 0.8em;
      position: absolute;
      bottom: 20px;
      left: 23px;
    }
  }

  .delete-icon-container {
    color: $edit-card-text-color;
    margin-top: 15px;
    letter-spacing: 0;
    font-size: 1.4em;
    .delete-icon {
      float: right;
      height: 30px;
      margin-bottom: -8px;
      width: 30px;
      color: $edit-card-color;
    }
  }

  .delete-confirmation {
    margin-top: 10px;
    .btn-confirm-card {
      letter-spacing: 1px;
      font-size: 1.4em;
      font-family: $primary-font;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px;
      display: inline-block;
      color: white;
      background: $edit-card-color;
    }
    .btn-cancel-card {
      letter-spacing: 1px;
      font-size: 1.4em;
      font-family: $primary-font;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px;
      display: inline-block;
      color: white;
      background: $remove-card-color;
      float: right;
    }
  }
}
