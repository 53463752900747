.ReactModalPortal {
  position: absolute;
  z-index: 10001;

  .ReactModal__Content {
    background: $black !important;

    &.app-modal {
      margin: 60px auto 0px auto;
      width: 90vw;
      max-width: 600px;
      padding: 15px 20px;
      max-height: 100vh;

      background: $primary-bg-color !important;

      @media #{$narrow-phone-bigger-width} {
        margin: 15px auto 0px auto;
        max-height: calc(100vh - 102px);
      }

      &.full-screen-mobile {
        @media #{$narrow-phone-bigger-width} {
          .modal-content {
            min-height: calc(100vh - 220px) !important;
          }
        }

        @media (max-width: 374px) {
          .modal-content {
            overflow-y: scroll;
            max-height: calc(100vh - 187px);
          }
        }
      }

      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0px;

        @media #{$narrow-phone-bigger-width} {
          margin: 0;
        }

        h2,
        svg {
          fill: $primary-text-color;
          color: $primary-text-color;

          @media #{$narrow-phone-bigger-width} {
            font-size: 24px;
          }
        }
      }
      .modal-content {
        margin-top: 15px;
        text-align: unset;
        display: flex;
        flex-direction: column;

        @media #{$narrow-phone-bigger-width} {
          margin-top: 0px;
        }
      }
    }

    .modal-content {
      text-align: center;

      &.contestant-agreement {
        width: 90vw !important;
        height: 100vw !important;

        @include breakpoint($desktop) {
          width: 90vw !important;
          height: 50vw !important;
        }
      }

      .detail-item-title {
        margin: 0;
        padding: 10px 0 0 0;
        text-transform: uppercase;
        font-size: 1.4em;
        font-weight: bold;
        font-family: $primary-font;
      }

      .btn-close {
        color: $primary-brand-color;
        position: relative;
        bottom: 105px !important;
        left: 50%;
        @include breakpoint($desktop) {
          margin-top: -100px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.container-iframe {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: 500px;
}
.object-iframe {
  width: 100%;
  height: 10000px;
}
.iframe-agreement-wrp {
  -webkit-overflow-scrolling: touch;
}
