.notification-container {
  background-color: $error-msg-bg-color;
  color: $error-message-text-color;
  border-radius: 0;
  padding: 12px;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  letter-spacing: 0;
  position: relative;

  &.info {
    background-color: $primary-brand-color;
    color: $primary-btn-text-color;
  }

  &.success {
    background-color: transparent;
  }
}
.notification-column {
  display: inline-block;
  max-width: 80%;
}
.notification-heading {
  color: $white;
  font-weight: 700;
  font-size: 16px;

  &.success {
    color: $green;
  }
}
.notification-message {
  color: $white;
  font-weight: 600;
  font-size: 14px;

  &.success {
    color: $green;
  }
}

.notification-infoMessage {
  text-transform: initial;
  font-size: 1rem;
  line-height: 23px;
  text-align: justify;
  font-weight: normal;
}

.notification-close-icon {
  button {
    display: flex;
    cursor: pointer;

    svg {
      color: $primary-btn-text-color;
    }
  }

  position: absolute;
  display: flex;
  right: 10px;
}

.notification-icon {
  height: 24px;
  width: 24px;
  color: $white;
  float: left;
  padding-right: 35px;

  &.success {
    color: $green;
  }
}
