.upcoming-events {
  .Select,
  .Select.is_focused,
  .Select.is_open {
    .Select-option {
      &.is-selected {
        &:hover .athlete-option > .count-indicator-wcra-selected {
          color: $dark-gray;
          background-color: $white;
          letter-spacing: normal;
        }

        &:hover .athlete-option > .count-indicator-ret-selected {
          color: $dark-gray;
          background-color: $white;
          letter-spacing: normal;
        }
      }
    }
  }

  .athlete-option {
    display: flex;
    align-items: center;

    & > .count-indicator {
      letter-spacing: normal;
    }
  }
}
