.ReactModalPortal {
  position: absolute;
  z-index: 10001;

  .ReactModal__Content {
    background: $black !important;

    .teammate-modal-content {
      min-width: 300px;
      text-align: left;
      padding: 12px;

      h2 {
        font-size: 1em;
        text-transform: uppercase;
      }
    }
  }
}

.selectable-contestant {
  cursor: pointer;
  padding-top: 10px;
  .photo-placeholder-wrapper {
    margin-right: 10px;
    float: left;
    bottom: 0;
    height: 41px;
    width: 41px;
  }
  .photo-placeholder-wrapper {
    background-color: $gray;
    border-radius: 50%;
  }
  .photo-placeholder {
    bottom: 0;
    height: 40px;
    margin-left: 9px;
    stroke: $dark-gray;
    width: 21px;
  }

  .contestant-name {
    color: $primary-brand-color;
    text-transform: uppercase;

    padding-top: 7px;
    display: inline-block;
  }

  .remove-icon {
    height: 15px;
    margin-top: 14px;
    width: 15px;
    float: right;
  }

  .clear {
    clear: both;
  }
}
