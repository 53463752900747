.error-bundary {
  font-family: 'Barlow';
  font-style: normal;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 2px;
    color: $black;
    margin-bottom: 20px;

    &.wcra {
      color: $primary-brand-color;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.4px;
    color: $dark-gray;
    margin-top: 0;
    margin-bottom: 70px;

    &.wcra {
      color: $primary-brand-color;
    }
  }

  a {
    text-decoration: none;
  }

  button {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
  }
}
