.confirm-partner-remove-modal {
  padding: 0 !important;
  height: 76vh;

  button {
    text-transform: uppercase;
  }

  .modal-header {
    border-bottom: 2px solid $hr-modal-color;
    padding: 15px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    .section-title {
      line-height: 1.2;
      font-size: 32px;
    }
  }

  .modal-content {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow-y: auto;
    margin-top: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;

    .prompt {
      margin-top: 6%;
    }
  }

  .footer {
    text-align: center;

    .btn-main {
      padding: 20px 10px;
    }
    .cancel-btn {
      color: $wcra-primary-ret-secondary-brand-color;
      border: none;
      padding: 30px 0;
      font-size: 16px;
      letter-spacing: 2px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
