$contestant-leaderboard-table-padding: 2.6875rem;

@mixin points-padding {
  padding-right: $contestant-leaderboard-table-padding;
}

.contestant-leaderboard-table {
  thead,
  tbody {
    background: none;
    border: none;
    tr,
    tr:nth-child(even) {
      background: none;
      background-color: none;
    }

    tr {
      &:not(:last-child) {
        padding-bottom: 1rem;
      }

      td:last-child {
        padding-right: 0.875rem;
      }

      td:first-child {
        padding-left: 0.875rem;
      }

      &.active-user {
        background-color: $primary-brand-color;

        td {
          &.points {
            color: $black;
          }

          &.name {
            a,
            span {
              color: $black !important;
            }
          }
        }
      }
    }
  }

  thead {
    text-align: left;
    color: $dark-gray2;
    font-weight: 500;
    letter-spacing: 2.1px;
    line-height: 1.0625rem;
    height: 1.0625rem;
    font-size: 0.875;
    text-transform: uppercase;
    padding-bottom: 5px;

    &.points {
      @include points-padding;
    }
  }

  td {
    color: $white;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.75rem;
    height: 2.75rem;

    &.rank {
      color: $dark-gray2;
      letter-spacing: 2.4px;
      min-width: $contestant-leaderboard-table-padding;
    }

    &.name {
      width: 100%;
      text-transform: uppercase;
    }

    &.points {
      @include points-padding;
    }
  }
}
