.desktop-menu-wrapper {
  background-color: $primary-bg-color;
  display: none;
  padding: 15px 0;

  @include breakpoint($desktop) {
    display: block;
  }
}
.mobile-menu-wrapper {
  background-color: $primary-bg-color;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;

  @include breakpoint($desktop) {
    display: none;
  }
}

#desktop-menu,
#mobile-menu {
  a {
    color: $white;
    font-size: 9px;
    font-family: $primary-font;
    display: table;
    height: 100%;
    line-height: 10px;
    letter-spacing: 1px;
    margin-top: auto;
    padding: 12px 1px;
    text-transform: uppercase;

    @include breakpoint($desktop) {
      padding: 12px 5px;
      letter-spacing: 1.5px;
      font-size: 10px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $wcra-primary-ret-secondary-brand-color;
      text-decoration: none;

      img {
        -webkit-filter: drop-shadow(0px 0px 14px $primary-brand-color);
        filter: drop-shadow(0px 0px 14px $primary-brand-color);
      }
    }

    .menu-item {
      display: table-cell;
    }
  }
  img {
    &.leaderboard-icon {
      height: 24px;
    }

    &.results-icon {
      height: 24px;
    }

    &.nominate-icon {
      height: 22px;
    }

    &.enter-icon {
      height: 25px;
    }

    &.dashboard-icon {
      height: 25px;
    }
  }
}

#desktop-menu {
  display: none;

  .menu-item {
    vertical-align: middle;
  }

  a {
    height: 50px;
  }

  img {
    display: inline;
    margin: 0 10px 0;
  }

  @include breakpoint($desktop) {
    display: flex;
  }

  .nav-link-counter {
    position: relative;
    top: -6px;
    right: 62px;
    text-indent: 0.06125rem;
  }
}

#mobile-menu {
  display: flex;

  .menu-item {
    vertical-align: bottom;
  }

  img {
    display: block;
    margin: 0 auto 3px;
  }

  a {
    padding: 12px 8px;

    @media #{$narrow-phone-width} {
      font-size: 9px;
      padding: 12px 4px;
    }
  }

  .nav-link-counter {
    position: relative;
    top: -6px;
    right: 16px;
    text-indent: 0.06125rem;
  }
}

.sticky-inner-wrapper {
  z-index: 1000;
}

.legal-links {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  a {
    margin: 0 10px;
  }
}
