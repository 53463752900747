/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: lighter;
  src: local('Barlow Regular'), local('Barlow-Regular'),
    url('../fonts/Barlow-Regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/Barlow-Regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: normal;
  src: local('Barlow Medium'), local('Barlow-Medium'),
    url('../fonts/Barlow-Medium.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/Barlow-Medium.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: bold;
  src: local('Barlow Bold'), local('Barlow-Bold'),
    url('../fonts/Barlow-Bold.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/Barlow-Bold.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
