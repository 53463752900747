.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.model-inner {
  background-color: white;
  border-radius: 0.5em;
  max-width: 860px;
  position: relative;
}

.modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: #0f0f0f;
}

.main-container {
  height: 100vh;
}

.button-container {
  padding: 30px;
}

.gift-card-container {
  border: 1px solid #dddddd;
  border-radius: 14px;
  height: 600px;
  margin: 10px;

  .gift-card-wrapper {
    position: relative;

    .gift-card-box-icon {
      position: absolute;
      top: 19px;
      right: 162px;
      opacity: 0.3;
    }

    .gift-card-vrq-icon {
      position: absolute;
      top: 22.32px;
      right: 22px;
    }

    .gift-card-name {
      position: absolute;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      top: 170px;
      right: 132px;
      left: 24px;
      width: 85%;
      font-weight: 700;
      font-size: 26px;
      color: #ffffff;
      line-height: 30px;
    }

    .gift-card-balance {
      position: absolute;
      top: 230px;
      right: 62px;
      left: 24px;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #ffffff;
    }

    %gift-card-dialog-rectangle {
      box-sizing: border-box;
      width: 100%;
      height: 271px;
      border-radius: 14px 14px 0 0;
    }

    .gift-card-rectangle-box1 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(136.39deg, #ee5253 0%, #b94e4e 97.69%);
    }
    .gift-card-rectangle-box2 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(138.01deg, #3398fe 3.81%, #2f5d8b 93.92%);
    }
    .gift-card-rectangle-box3 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(137.21deg, #ffc404 1.62%, #c49707 98.05%);
    }
    .gift-card-rectangle-box4 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(137.21deg, #a574f8 1.62%, #698cf7 98.05%);
    }
    .gift-card-rectangle-box5 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(137.21deg, #242424 1.62%, #525252 98.05%);
    }
    .gift-card-rectangle-box6 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(136.39deg, #97ee52 0%, #63b522 97.69%);
    }
    .gift-card-rectangle-box7 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(138.01deg, #33f2fe 3.81%, #23abb4 93.92%);
    }

    .gift-card-rectangle-box8 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(137.21deg, #f874bb 1.62%, #ca5192 98.05%);
    }

    .gift-card-rectangle-box9 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(138.01deg, #fe7c33 3.81%, #d3682b 93.92%);
    }

    .gift-card-rectangle-box10 {
      @extend %gift-card-dialog-rectangle;
      background: linear-gradient(137.21deg, #f8db74 1.62%, #dab739 98.05%);
    }
  }

  .gift-card-scan-wrapper-bg {
    background: #f6f6f6;
    flex: 1;
  }

  .gift-card-scan-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    .main-title {
      text-align: center;

      .gift-card-label {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.gift-card-second-container {
  color: #000000;
  font-family: 'Barlow';
  font-style: normal;
  padding: 5px;

  .main-title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }

  .title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  hr {
    background-color: #d9d9d9;
  }

  .user {
    padding-bottom: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;

    .title {
      color: #b3b3b3;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .gift-card-download-paragraph {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .terms-condition-title {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-top: 180px;
  }
}
