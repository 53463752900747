@mixin toast-content {
  font-size: 14px;
  font-weight: bold;
  line-height: 1rem;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.react-toast-notifications__container {
  padding-top: 8vh !important;
  overflow: hidden !important;

  .react-toast-notifications__toast {
    border-radius: 0 !important;
    background-color: $primary-brand-color !important;
    color: $btn-focus-color !important;

    .react-toast-notifications__toast__content {
        @include toast-content;
    }
    .react-toast-notifications__toast__icon-wrapper {
      display: none;
    }
    .react-toast-notifications__toast__dismiss-button {
      padding: 7px 10px;
      svg {
        width: 22px;
        height: 22px;
      }
    }

    &--error {
      background-color: $redError !important;

      .react-toast-notifications__toast__content {
        @include toast-content;
        color: $white;
      }

      .react-toast-notifications__toast__dismiss-button {
        display: none;
      }
    }
  }
}
