@mixin divisionStyleIcon($trophyName) {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  -webkit-mask: url('../img/icons/#{$trophyName}.svg') no-repeat 100% 100%;
  mask: url('../img/icons/#{$trophyName}.svg') no-repeat 100% 100%;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.event-result,
.event-selected {
  color: $event-title-color;
  display: block;
  font-size: 16px;
  font-family: $primary-font;
  line-height: 22px;

  @include breakpoint($desktop) {
    text-align: center;
  }

  a {
    color: $primary-brand-color;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  .event-title {
    text-transform: uppercase;
  }

  .event-dates,
  .event-entry-deadline {
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: capitalize;
  }

  .event-title,
  .event-location,
  .event-dates,
  .event-entry-deadline,
  .event-discipline {
    display: block;
  }

  .event-title {
    font-size: 20px;
    line-height: 24px;
  }
}

.event-result {
  text-align: left;
  margin: 20px 0 0;
  max-width: 100%;
  background-color: $event-search-card-bg-color;
  padding: 12px 0 0;
  overflow-wrap: break-word;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);

  &:hover {
    outline: 2px $event-result-border-hover solid;
    cursor: pointer;
    text-decoration: none;
    -webkit-transition: background-color $transition_speed_mid;
    transition: background-color $transition_speed_mid;
  }

  .event-title {
    margin-top: 12px;
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .event-title,
  .event-location,
  .event-dates,
  .event-entry-deadline,
  .event-discipline {
    text-align: left;
    padding: 0 15px;
  }

  .event-location {
    padding-bottom: 10px;
  }

  .event-dates img,
  .event-entry-deadline img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .event-dates {
    padding-bottom: 5px;
  }

  .event-entry-deadline {
    padding-bottom: 10px;
  }

  .event-image {
    background-color: #fff;
    padding: 0;

    img {
      width: 100%;
    }
  }

  .association-container {
    margin-left: 13px;
  }

  .association-short-name {
    position: relative;
    color: $alliance-label-color;
    font-size: 13px;
    padding: 2px 3px 4px 11px;
    background-color: $alliance-tag-color;

    .and-separator {
      padding-left: 10px;
    }
  }

  .associations-finish-tag {
    width: 5px;
    position: absolute;
    display: inline-flex;
    margin-top: 2px;

    &:before {
      content: '';
      border-right: 5px solid transparent;
      border-top: 10px solid $alliance-tag-color;
      border-bottom: 12px solid $alliance-tag-color;
    }
  }

  .division-and-partner-container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    color: $white;
    text-align: center;
    margin-top: 0;
    padding: 0 18px 12px;
    letter-spacing: 1px;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 28px;

    .event-division {
      letter-spacing: 1px;
      display: flex;
      align-items: center;
    }

    .partner-badge {
      font-size: 13px;
      color: #8ce04a;
      display: flex;
      letter-spacing: 1px;
      padding-left: 18px;

      img {
        margin-right: 3px;
      }
    }
  }
}

.association-title-container {
  color: $event-title-color;
  text-transform: none;
}

.radio-group-container {
  margin-top: 1rem;
}

.radio-group-title {
  padding-top: 5px;
  text-transform: none;
  color: $event-title-color;
  font-family: "Barlow", san-serif;
}

.radio-group-label {
  margin-top: 1rem;
  color: $primary-brand-color !important;
}

.radio-group-radio-label {
  color: $medium-gray !important;
}

.radio-group-radio {
  color: $primary-outline-color !important;
}

.radio-group-radio-checked {
  color: $secondary-brand-color !important;
}

.radio-group-label2 {
  margin-top: -5px;
}

.forgot-number {
  color: $secondary-brand-color;
}

.upcoming-event {
  padding: 18px 0 0;

  &:hover {
    outline: 2px $event-result-border-hover solid;
    text-decoration: none;
    cursor: default;

    .event-title {
      color: $event-title-color;
    }
  }

  .event-location {
    padding-top: 5px;
  }

  .event-discipline {
    display: block;
    padding: 20px 15px;
    color: $gray;
    font-size: 1.2em;
  }

  .complete-entry {
    background-color: $primary-brand-color;
    color: $banner-text-color;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }

  hr {
    margin: 10px 15px;
    padding: 0 15px;
  }
}

.upcoming-subsection-title {
  font-weight: 700;
  color: $primary-text-color;
  font-size: 24px;
  font-family: $primary-font;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.upcoming-subsection-subtitle {
  color: $primary-text-color;
  font-size: 16px;
  font-family: $primary-font;
  margin-bottom: 15px;
}

.events-filters-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  flex-direction: row;

  .btn-main {
    background-color: $primary-brand-color;
    color: $event-filter-color;
    font-size: 13px;
    padding: 10px 15px;
    font-weight: bold;
    &:hover {
      background-color: rgba($primary-brand-color, 0.9);
    }
  }

  > div {
    width: 100%;
  }

  &.has-total-filters {
    button {
      padding-right: 35px;
    }
  }

  button {
    height: unset !important;
    max-width: fit-content !important;
    margin: 0 !important;
    margin-left: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: normal;
    padding: 10px;
    position: relative;

    .filter-icon {
      width: 18px;
      margin-right: 10px;
    }

    .total-filters {
      position: absolute;
      font-size: 10px;
      line-height: normal;
      padding: 0px;
      white-space: normal;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-bg-color;
      color: $primary-text-color;
      min-width: 20px;
      min-height: 20px;
      border-radius: 50%;
      right: 5%;
      top: 27%;
    }
  }
}

.event-search-container {
  position: relative;

  hr {
    &:first-child {
      margin-top: 17px;
      margin-bottom: 7px;
    }

    background-color: #7e7e7e;
    margin: 10px auto 15px auto;
  }

  .search-icon {
    position: absolute;
    right: 10px;
    color: $primary-brand-color;
    top: 20px;
    width: 20px;
    height: 20px;
  }

  input {
    padding-right: 40px;
    &::placeholder {
      color: #7e7e7e !important;
      opacity: 1 !important;
    }
  }
}

.division-rank {
  display: inline-block;
  text-align: center;
  font-size: 0;

  &.division-0,
  &.division-1,
  &.division-2,
  &.division-3,
  &.division-4,
  &.division-5,
  &.division-6,
  &.division-7,
  &.division-8,
  &.division-9,
  &.division-10,
  &.division-11 {
    @include divisionStyleIcon('trophy');
  }

  &.division-6 {
    background: linear-gradient(270deg, #E8E8E8 0%, #FFFFFF 53.75%, #E8E8E8 100%);
  }

  &.division-7 {
    background: linear-gradient(270deg, #FFC404 0%, #FDE491 53.75%, #FFC404 100%);
  }

  &.division-8 {
    background: linear-gradient(270deg, #9D9D9D 0%, #C8C8C8 53.75%, #9D9D9D 100%);

  }

  &.division-9 {
    background: linear-gradient(270deg, #CD7F32 0%, #FFCA96 53.75%, #CD7F32 100%);
  }

  &.division-10 {
    background: linear-gradient(270deg, #155B7A 0%, #329ECD 53.75%, #155B7A 100%);
  }

  &.division-11 {
    background: linear-gradient(270deg, #8d70ff 0%, rgba(141, 112, 255, 0.4), 53.75%, #8d70ff 100%);
  }

  &.division-0 {
    background: rgb(21, 91, 122);
    background: linear-gradient(
      90deg,
      rgba(21, 91, 122, 1) 30%,
      rgba(65, 155, 199, 1) 50%,
      rgba(21, 91, 122, 1) 70%
    );
  }
  &.division-1 {
    background: rgb(232, 232, 232);
    background: linear-gradient(
      90deg,
      rgba(232, 232, 232, 1) 30%,
      rgba(255, 255, 255, 1) 50%,
      rgba(232, 232, 232, 1) 70%
    );
  }
  &.division-2 {
    background: rgb(241, 186, 5);
    background: linear-gradient(
      90deg,
      rgba(241, 186, 5, 1) 30%,
      rgba(255, 234, 123, 1) 50%,
      rgba(241, 186, 5, 1) 70%
    );
  }
  &.division-3 {
    background: rgb(241, 186, 5);
    background: linear-gradient(
      90deg,
      rgba(153, 153, 153, 1) 30%,
      rgba(255, 255, 241, 1) 50%,
      rgba(153, 153, 153, 1) 70%
    );
  }
  &.division-4 {
    background: rgb(207, 128, 50);
    background: linear-gradient(
      90deg,
      rgba(207, 128, 50, 1) 30%,
      rgba(255, 209, 169, 1) 50%,
      rgba(207, 128, 50, 1) 70%
    );
  }

  &.division-5 {
    background: linear-gradient(270deg, #155B7A 0%, #329ECD 53.75%, #155B7A 100%)!important;
  }
}

.no-events-available {
  font-size: 16px;
}

.entry-no-results {
  h3 {
    color: $primary-text-color;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 24px;
    text-align: left;
    text-transform: uppercase;
    margin-top: 24px;
  }

  p {
    color: $primary-input-color;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 19px;
    text-align: left;
    margin-bottom: 28px;
  }

  .btn-main {
    padding: 15px 28px;
  }
}

.theme-content-container {
  .entry {
    .association-short-name:first-child {
      margin-left: -24px;
    }
  }
  &.entry {
    .association-short-name {
      margin-top: 0;
      margin-bottom: 0;
      position: relative;
      display: inline-block;
      color: $alliance-label-color;
      font-size: 13px;
      padding: 3px 3px 2px 11px;
      background-color: $alliance-tag-color;

      @include breakpoint($tablet) {
        margin-left: 0px;
      }

      .and-separator {
        padding-left: 10px;
      }
    }

    .associations-finish-tag {
      margin-top: 1px;
      width: 5px;
      position: absolute;
      display: inline-flex;

      &:before {
        content: '';
        border-right: 5px solid transparent;
        border-top: 13px solid $alliance-tag-color;
        border-bottom: 12px solid $alliance-tag-color;
      }
    }
  }
}
