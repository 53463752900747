.button-group-container {
  padding: 0px, 4px;

  > .label,
  label {
    line-height: unset;
    text-align: left !important;
    font-size: 16px;
    display: block;
    letter-spacing: 1.5px;
    width: 100%;
    margin-bottom: 2px;
  }

  .button-group {
    position: relative;
    .button {
      color: $primary-input-color;
      border-radius: 0px;
      border: 3px solid $primary-brand-color;
      background-color: transparent;
      text-transform: uppercase;
      letter-spacing: normal;
      font-weight: bold;
      flex-grow: 1;
      outline: none !important;
      padding: 0.75rem 0.85rem;
      font-size: 16px;
      line-height: 19px;
      margin-right: 0px !important;
      max-width: 50%;

      &:not(:first-child):not(:last-child) {
        border-right: 0px;
        border-left: 0px;
      }

      &:last-child {
        background-color: $white;
      }

      &.active {
        background-color: $select-value-selected;
        border: 2px solid $primary-btn-border;
        color: $select-value-bg-color;
        z-index: 999;
      }

      &:hover:not(.active) {
        background-color: $primary-brand-color-opacity;
        border-color: $primary-btn-border;
        color: $event-type-hover-color;
        z-index: 999;
      }

      &:hover:not(.active) {
        transition: background-color 0.2s;
      }

      &:disabled {
        background-color: $primary-btn-bg;
        border: 2px solid $disabled-button-color;
        color: rgba(255, 255, 255, 0.5);
        cursor: auto;
        opacity: 50%;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
    }
  }
}
