.card-data-section.card-data-section-entry {
  background: transparent;
  margin-bottom: 100px;

  form {
    .field-row {
      border-bottom: 2px solid #313131;

      .icon {
        color: $gray;
        display: inline-block;
        padding-right: 5px;
      }
    }

    .cvc-column {
      border-left: 1px solid #313131;
    }

    .card-input {
      color: $primary-input-color;
      &::placeholder {
        text-transform: initial;
        font-size: 0.8em;
        color: #9d9d9d;
      }
    }
  }

  .saved-cards-section {
    h2 {
      padding: 15px;
      font-size: 1em;
      text-transform: uppercase;
    }

    .brand-card {
      display: inline-block;
      min-width: 100px;

      @media (max-width: $phone-width) {
        min-width: 55px;
        margin-left: 0;
      }

      @media (max-width: 372px) {
        min-width: 50px;
        margin-left: 0;
      }
    }

    .brand-card-number {
      @media (max-width: $phone-width) {
        float: right;
      }
    }

    .brand-card,
    .brand-card-number {
      @media (max-width: 320px) {
        font-size: 13px;
      }
    }

    ul {
      border-top: 2px solid #313131;

      li {
        border-bottom: 2px solid #313131;

        &:hover {
          color: $saved-cards-hover-color;
        }

        @media (max-width: 372px) {
          font-size: 14px;
        }
      }
    }
  }
}

.pay-later-button-wrp {
  text-align: center;
  margin: 60px auto 0 auto;
  display: block;
  padding-bottom: 150px;
  .pay-later-button {
    color: $primary-brand-color;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 19px;
    text-transform: uppercase;

    .arrow-right {
      height: 18px;
      margin-bottom: 3px;
      vertical-align: middle;
    }
  }
}

.us-citizen {
  .checkbox-wrp {
    display: table;
    width: 100%;
    padding-bottom: 15px;

    .input-checkbox {
      display: table-cell;
    }

    label {
      display: table-cell;
      padding-left: 10px;
      padding-top: 7px;
    }
  }
  .validation-message {
    display: block;
    margin: auto;
    width: 300px;
  }
}
