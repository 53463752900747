html,
body {
  background-color: $primary-bg-color;
  color: $white;
  font-family: $primary-font;
  font-weight: 500;
  height: 100vh;
  letter-spacing: 1.4px;
}

header,
nav,
footer {
  flex-shrink: 0;
}

footer {
  min-height: 62px;

  @include breakpoint($desktop) {
    min-height: auto;
  }
}

div,
button,
span {
  &:focus {
    outline: 0;
  }
}

main {
  color: $primary-text-color;
  font-family: $primary-font;
  flex: 1 0 auto;
  min-height: calc(100vh - #{$header-height});
  .grid-container {
    padding-bottom: 50px;
  }
}

#main-top {
  min-height: calc(100vh - #{$footer_offset_bottom});

  @include breakpoint($desktop) {
    min-height: calc(100vh - #{$footer_offset_bottom} - #{$header-height});
  }
}
#main-bottom {
  height: $footer_offset_bottom;
  padding-top: 20px;

  @include breakpoint($desktop) {
    padding-top: 50px;
  }
}

hr {
  background-color: $hr-color;
  border: none;
  color: $dark-gray2;
  height: 2px;
  margin: 17px auto;
}

.or-line {
  width: 100%;
  text-align: left;
  border-bottom: 2px solid $hr-color;
  line-height: 0.1em;
  margin: 20px 0 20px;
}

.or-text {
  background: $primary-bg-color;
  padding: 0 10px 0 0;
  font-size: 1.25rem;
  font-weight: bold;
}

label,
.label {
  background-color: transparent;
  color: $primary-label-color;
  display: block;
  font-family: $primary-font;
  font-size: 14px;
  line-height: 14px;
  padding: 0;
  position: relative;
  text-transform: uppercase;
}

small {
  color: $gray;
  font-size: 12px;
  text-transform: uppercase;
}

a {
  color: $wcra-primary-ret-secondary-brand-color;

  &:hover,
  &:focus,
  &:active {
    color: $wcra-primary-ret-secondary-brand-color;
    text-decoration: underline;
  }
}

select,
input,
input:focus,
[type='color'],
[type='color']:focus,
[type='date'],
[type='date']:focus,
[type='datetime-local'],
[type='datetime-local']:focus,
[type='datetime'],
[type='datetime']:focus,
[type='email'],
[type='email']:focus,
[type='month'],
[type='month']:focus,
[type='number'],
[type='number']:focus,
[type='password'],
[type='password']:focus,
[type='search'],
[type='search']:focus,
[type='tel'],
[type='tel']:focus,
[type='text'],
[type='text']:focus,
[type='time'],
[type='time']:focus,
[type='url'],
[type='url']:focus,
[type='week'],
[type='week']:focus,
textarea {
  background: transparent;
  border: none;
  box-shadow: none;
  color: $primary-input-color;
  font-family: $primary-font;
  font-size: 20px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;

  &::placeholder {
    color: $primary-placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $primary-placeholder-color;
  }
}

select {
  border: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      $primary-brand-color 50%
    ),
    linear-gradient(135deg, $primary-brand-color 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 0.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    background-repeat: no-repeat;
    outline: 0;
  }
}

#root,
.application,
.layout-wrapper,
.layout {
  min-height: 100vh;
}

.off-canvas-wrapper {
  overflow: visible;
}

.off-canvas-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.top-bar,
.top-bar ul {
  background-color: transparent;

  a {
    color: $white;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $primary-placeholder-color !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $primary-placeholder-color !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $primary-placeholder-color !important;
}

.notification-errorMessage,
.notification-container {
  margin-top: 2px;
}

.discipline-name-area {
  color: $black;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: $preferences-bg-color;

  &.withSubtitle {
    padding-bottom: 0px;
  }

  .subtitle {
    font-size: 0.9em;
    text-transform: initial;
    font-weight: 100;
    padding-bottom: 15px;
  }

  @media (max-width: 415px) {
    margin-right: $container-mobile-padding;
    margin-left: $container-mobile-padding;
  }
}