.discipline-option {
  display: flex;
  align-items: center;

  .star {
    margin-right: 0.375rem;
    color: $primary-brand-color;

    &-is-selected {
      color: $black;
    }

    &-is-focused {
      color: $primary-brand-color;
    }
  }
}
