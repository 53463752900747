.home-title-yellow {
  color: $primary-brand-color;
  display: block;
  font-size: 1.3em;
  padding-top: 64px;

  @include breakpoint($desktop) {
    font-size: 1.8em;
  }
}

.master_password_container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  >div{
     width: 50%;
     height: 50%;
  }
}

.home-subheader {
  color: $home-subheader-color;
  display: block;
  font-size: 1.3em;
  padding-bottom: 25px;

  @include breakpoint($desktop) {
    font-size: 1.8em;
    padding-bottom: 64px;
  }
}

.home-signin {
  color: $home-subheader-color;
  display: block;
  font-size: 0.7em;
  padding: 5px 0 5px 0;

  @include breakpoint($desktop) {
    font-size: 1.8em;
  }
}

.home-buttons {
  width: 100%;

  a:nth-of-type(2) {
    margin-left: 15px;

    @media (max-width: 674px) {
      margin-top: 0;
    }
  }

  .btn-main {
    width: 306px;
    margin: 10px 0 10px;
    text-decoration: none;
    display: inline-block;

    @media (max-width: 674px) {
      margin: 20px;
    }
  }

  .btn-ret {
    background: transparent;
    border: solid $home-signinwith-color;
    color: $home-signinwith-color;
    font-size: 16px;
    font-weight: 400 !important;
    min-height: 51px !important;
    height: 50px;
    letter-spacing: 0;
    padding: 10px 30px;
  }
}

.ret-logo-only {
  height: 30px;
  margin-top: -3px;
}

.home-page-container {
  padding-top: 25vh;
}

.splash-screen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  -webkit-transition: $transition_speed_long;
  -moz-transition: $transition_speed_long;
  -o-transition: $transition_speed_long;
  transition: $transition_speed_long;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.terms-message {
  color: $gray;
  font-size: 16px;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.terms-message-home-page {
  color: $gray;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px ;
  text-transform: uppercase;
}

.registration-form,
.multi-select-container {
  .form-checkbox-wrapper {
    display: flex;
    padding-bottom: 12px;
  }

  .checkbox-label {
    display: inline-block;
    font-size: 16px;
    color: $checkbox-label-color;
    letter-spacing: 2.4px;
    padding-left: 8px;
    vertical-align: middle;
    line-height: 19px;
    max-width: 250px;
    margin-top: 8px;

    @media #{$narrow-phone-width} {
      font-size: 14px;
    }
  }

  .terms-and-conditions {
    width: calc(100% - 40px);
    display: inline-block;
    margin-left: 10px;
  }

  .terms-container {
    padding-top: 50px;

    .terms-visible {
      display: table;
      width: 100%;
      padding-bottom: 15px;

      label {
        display: table-cell;
        padding-left: 10px;
      }
    }
    .validation-message {
      display: block;
      margin: auto;
      width: 300px;
    }
  }

  .validation-email-message {
      color: $red
  }
  .martop-0-email {
      margin-top: 0 !important;
      background-color: $red;
  }

  .validation-phone-message {
      color: $yellow
  }
  .martop-0-phone {
      margin-top: 0 !important;
      background-color: $yellow;
  }
}

.brand-image {
  display: block;
  margin: 32px auto 0;
  padding-bottom: 10px;
  width: 227px;

  @media (max-width: 767px) {
    display: none;
  }
}

.brand-image-subtext {
  margin: 0 0 20px 0;
}
