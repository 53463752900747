.entries-button-group {
  .button-group-container {
    .button-group {
      .button {
        color: $primary-text-color;

        &.active {
          color: $btn-focus-color;
        }
      }
    }
  }
}
