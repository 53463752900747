.view-cart-button-sticky-wrapper {
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 30%);

  @media (max-width: 1023px) {
    bottom: 62px;
  }

  .view-cart-button {
    width: 307px;
    height: 50px;
    color: $view-cart-btn-text;
    background: $view-cart-btn-background;
    border-radius: 25px;
    cursor: pointer;
    display: flex;

    &.disabled {
      opacity: 0.5;
    }

    .cart-icon {
      display: flex;
      justify-content: center;
      padding-left: 7px;
    }

    .cart-count-indicator {
      display: flex;
      justify-content: center;

      div {
        border-radius: 100%;
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 13px;
        text-align: center;

        &.ret {
          color: $dark-gray;
          background-color: $white;
        }

        &.wcra {
          color: $white;
          background-color: $dark-gray;
        }
      }
    }

    .button-text {
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 2.4px;
    }
  }
}
