.entry-confirmation,
.enter-landing-view,
.preferences-view,
.review-view,
.nomination {
  .form-checkbox-wrapper {
    display: flex;

    .MuiFormControl-root{
      display: inline-block;

      .MuiFormGroup-root {
        display: inline-block;
      }

      .radio-group-label {
        margin-top: 0;
        margin-right: 10px;
      }
    }

    label {
      vertical-align: baseline;
      margin-left: 0;
      margin-top: 0;
      display: inline-block;
      margin-right: 0;
      color: white!important;

      @media (max-width: 1024px) {
        margin-top: 10px;
      }

      .radio-group-title  {
        color: white;
        text-transform: uppercase;

        background-color: transparent;
        display: block;
        font-family: Barlow,san-serif;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;
    padding-bottom: 100px!important;
  }

  margin-bottom: 100px;

  .label {
    padding: 5px 0;
  }

  .section-preferences-subtitle {
    padding-top: 0;
    margin-top: 10px;
  }

  .partner-badge {
    color: #8ce04a;
    text-transform: uppercase;
    font-size: 14px;
  }

  .event-division.division-rank {
    width: 105px;
    display: inline-block;
  }
}

.legal-info-view {
  .warning-icon {
    img {
      width: 25px;
      margin-top: -5px;
      padding-right: 5px;
    }
  }
}

.nomination-checkout-form {
  @media (max-width: 1024px) {
    height: auto;
    padding-bottom: 0px!important;
  }
}
