.select-discipline {
  .Select-option {
    &:hover {
      svg {
        color: $white;
      }
    }
  }
}

.select-multiline-label {
  white-space: pre-wrap !important;

  .Select-option {
    text-transform: none !important;
  }

  .Select-option::first-line {
    text-transform: uppercase !important;
  }
}
