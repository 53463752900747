.buddy-search {
  .select-wrapper {
    input {
      width: 100%;
      margin-bottom: 10px;

      &:focus {
        outline: none;
      }
    }

    .Select-input {
      padding-left: 0;
    }

    .Select-placeholder {
      color: $primary-placeholder-color;
    }

    .Select-menu-outer {
      background: $multi-select-item-bg-color;
      @include partner-options-shadow;

      .Select-option {
        background: $multi-select-item-bg-color;

        &:hover,
        &:focus,
        &:active {
          background: $primary-brand-color;
          color: $entry-partner-search-option-font-color;
        }
      }
    }
  }
}
