.Select,
.Select.is_focused,
.Select.is_open {
  &:hover {
    .Select-placeholder,
    .Select-value {
      cursor: pointer;
    }
  }
  .Select-control {
    border: 0px transparent !important;
    margin-bottom: 4.5px !important;
    background: transparent !important;
    text-transform: uppercase !important;

    &:hover {
      box-shadow: none;
    }

    .Select-input:focus {
      background: transparent !important;
    }
    .Select-value,
    .Select-value-label {
      color: $select-value-color !important;
    }
  }

  .Select-placeholder,
  .Select-value {
    color: $dark-gray;
    font-size: 20px;
    padding-left: 0;
  }
  .Select-arrow-zone .Select-arrow,
  .Select-arrow {
    border-color: $primary-brand-color transparent transparent;

    &:hover {
      border-top-color: $select-value-bg-hover;
    }
  }
  .Select-menu {
    max-height: 315px;
  }
  .Select-menu-outer {
    background: $select-value-bg-color;
    border: transparent;
    font-size: 18px;
    font-weight: bold;
    max-height: 315px;
  }
  .Select-option {
    background: $select-value-bg-color;
    color: $select-value-color;
    padding: 15px;
    text-transform: uppercase;

    &:hover {
      background: $select-value-bg-hover;
    }

    &.is-open,
    &.is-focused {
      background: $select-value-bg-color;

      &:hover {
        background: $select-value-bg-hover;
        color: $white;
        small {
          color: $white;
        }
      }
    }
    &.is-selected {
      background: $primary-brand-color;
      color: $dark-gray;

      small {
        color: $black;
      }
      &:hover {
        background: $select-value-bg-hover;
      }
    }
  }
  &.has-value.Select--single {
    .Select-control .Select-value {
      padding-left: 0px;
      .Select-value-label {
        color: $select-value-color;
      }
    }
  }
  &.is-focused:not(.is-open) > .Select-control {
    border-color: transparent;
  }
}
