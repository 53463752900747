.form-link-container {
  > button {
    color: $primary-brand-color;
    text-transform: uppercase;

    &:focus {
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.trash-athlete-button {
  color: $primary-brand-color;
  text-transform: uppercase;

  > img {
    width: 20px;
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }
}

.youth-athlete-container {
  padding: 1.33em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(.has-athletes) {
    padding: 0 0 50px 0;
  }

  &.has-athletes {
    padding: 1.33em 0;
    border-bottom: 2px solid $dark-gray;
    text-transform: uppercase;

    &#athlete0 {
      border-top: 2px solid $dark-gray;
    }
  }
}
