@mixin entry-subtitle-text {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 29px;
  font-family: $primary-font;
}

.entry-confirmation {
  .mobile-title.mobile-only {
    padding-bottom: 6px;
  }

  .event-result {
    border: none;
    cursor: unset;
    padding-top: 0;
    background-color: transparent;
    box-shadow: none;

    &.entry {
      margin: 0;

      .label {
        padding: 0;
      }
    }

    &:hover {
      outline: none;
    }

    .event-title {
      text-align: left;
      margin-bottom: 8px;
    }

    .event-location {
      padding: 0 0 10px;
    }

    .event-dates {
      padding: 0 0 5px;
    }

    .event-entry-deadline {
      padding: 0 0 10px;
    }

    .section-preferences-subtitle {
      padding-top: 10px;
      padding-bottom: 5px;
    }
  }

  .show-event-messaging-confirmation-container {
    padding-top: 15px;
  }

  .confirmation-text-container {
    .confirmation-title {
      display: flex;
      color: $white;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 2.7px;
      line-height: 22px;
      text-transform: uppercase;
      padding-bottom: 5px;

      &.disciplines-entered {
        font-size: 18px;
        letter-spacing: 2.7px;
        padding-top: 25px;
      }
    }

    .warning-icon {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-right: 5px;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .entry-details-text {
      margin-top: 25px;
      @include entry-subtitle-text;
    }

    .buddy-group-text {
      @include entry-subtitle-text;
    }

    .confirmation-text {
      font-size: 16px;
      letter-spacing: 1.05px;
    }

    .confirmation-discipline {
      padding-top: 15px;
      font-weight: bold;
    }
    .confirmation-classname {
      font-weight: 400;
      padding-top: 8px;
      padding-bottom: 10px;

      .entry-description {
        margin-top: 0.5rem;
      }

      .partner-label {
        text-transform: capitalize;
      }
    }
  }

  .confirmation-partner-container {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .total-container {
    display: flex;
    justify-content: space-between;
    padding-top: 29px;

    div {
      display: inline-block;
    }

    .total-paid {
      color: $primary-brand-color;
    }
  }

  .social-links-text-area {
    padding-top: 0;
    font-size: 16px;
    padding-bottom: 8px;
    letter-spacing: 1.05px;
  }

  .division-and-partner-container {
    padding: 0;
    display: flex;
    background-color: transparent;
    justify-content: flex-start;
  }
}

.nomination-confirmation:not(.entry-confirmation) {
  .mobile-title.mobile-only {
    padding-bottom: 0;
  }
}

.nomination-confirmation.entry-confirmation {
  .input-chosen,
  .nomination-description {
    display: block;
  }

  .mobile-title.mobile-only {
    margin-bottom: 0;

    @media (max-width: 370px) {
      font-size: 28px;
    }

    @media (max-width: 346px) {
      font-size: 26px;
    }

    @media (max-width: 330px) {
      font-size: 24px;
    }
  }

  .partner-email-invite-link {
    color: $secondary-brand-color;
    font-size: 13px;
    letter-spacing: 1.63px;
    text-transform: uppercase;
    line-height: 25px;

    .partner-invite {
      padding-left: 10px;
    }

    img {
      padding-bottom: 4px;
    }

    svg {
      fill: $secondary-brand-color;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .nominations {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .entry-buddy-manager {
    font-size: 0.8125rem;
    color: $primary-brand-color;
    letter-spacing: 0.81px;
    text-transform: none;
    font-weight: 500;
  }
}

.membership-language {
  display: flex;
}

.alert-icon-container {
  font-size: 20px;
}
