.calendar {
  text-align: center;
}
.react-datepicker {
  background-color: transparent;
  border: none;
  color: $white;
  font-family: $primary-font;
  font-size: 11px;

  .react-datepicker__header {
    background-color: transparent;
    border: none;

    .react-datepicker__current-month {
      color: $white;
      font-size: 16px;
      font-weight: normal;
    }

    .react-datepicker__day-name {
      margin-bottom: 0px;
    }
  }

  .react-datepicker__month-container {
    background-color: $primary-bg-color;
    border: 2px solid $dark-gray;
    border-radius: 5px;
  }

  .react-datepicker__month {
    margin-top: 0px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: $white;
    text-decoration: underline;

    &.odds-begin,
    &.preference-selected {
      color: $green;
      text-decoration: underline;
    }
    &.odds-end,
    &.preference-unavailable {
      color: $red;
      text-decoration: underline;
    }
    &.preference-out-selected {
      color: $primary-brand-color;
      text-decoration: underline;
    }
  }
  .react-datepicker__day--highlighted {
    color: $red !important;
  }
  .react-datepicker__day {
    background-color: transparent;
    border: none;
    border-radius: 100%;
    height: 36px;
    line-height: 34px;
    width: 36px;

    &.react-datepicker__day--disabled.nominations-open {
      color: $primary-brand-color;
      text-decoration: none;
    }
  }
  .react-datepicker__day--disabled {
    background-color: transparent;
    color: $gray;
    text-decoration: none;

    &:hover {
      background-color: transparent;
    }
  }
}
.calendar-key {
  color: $gray;
  font-size: 10px;
  margin: 0 auto;
  max-width: 305px;
  padding-bottom: 15px;
  text-align: center;

  @include breakpoint($tablet) {
    max-width: 570px;
    text-align: center;
  }

  @include breakpoint($tablet) {
    font-size: 11px;
  }

  .calendar-key-title {
    padding-bottom: 10px;
  }

  .calendar-key-type {
    display: inline-block;
    padding: 5px 10px;

    @include breakpoint($tablet) {
      padding-top: 5px;
    }
  }
  .calendar-key-type-left {
    @include breakpoint($tablet) {
      padding-left: 0;
    }
  }
  .icon-days-of-event,
  .icon-odds-begin,
  .icon-odds-end,
  .icon-nominations-open {
    background-color: $white;
    border-radius: 100%;
    display: inline-block;
    margin-bottom: 1px;
    margin-right: 8px;
    height: 10px;
    line-height: 10px;
    width: 10px;
  }
  .icon-odds-begin {
    background-color: $green;
    border: $green 1px solid;
  }
  .icon-odds-end {
    background-color: $red;
    border: $red 1px solid;
  }
  .icon-nominations-open {
    background-color: $primary-brand-color;
    border: $primary-brand-color 1px solid;
  }
}
.calendar-warning-message {
  color: $white;
  display: block;
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 8px;
  text-transform: uppercase;

  @include breakpoint($tablet) {
    font-size: 15px;
  }
}
.perf-preferences-label {
  color: $gray;
  display: block;
  font-size: 13px;
  font-weight: lighter;
  padding-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  @include breakpoint($tablet) {
    font-size: 12px;
  }
}
.form-disabled {
  .calendar {
    margin-bottom: 30px;
  }
  .react-datepicker__day {
    cursor: default;

    &:hover {
      cursor: default;
    }
  }
  .calendar-select-field .selectable-area {
    cursor: default;

    &:hover {
      cursor: default;
    }
    .label,
    .label:hover {
      color: $gray;
      cursor: default;
    }
  }
}
.past-nomination-window {
  text-decoration: line-through;
}

.birthday-picker {
  &.disabled{
    color: $gray;
  }
  input {
    display: inline;
    width: 28px;
    &:disabled{
      color: $gray;
      background-color: transparent;
    }
    &.year {
      width: 50px;
    }
  }
}
