.share-buttons-wrp {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  .SocialMediaShareButton {
    float: left;
    .share-button {
      text-transform: uppercase;
      font-weight: bold;
      color: $white;
      display: inline-block;
      font-size: 1.3em;
      cursor: pointer;

      padding: 15px 15px;
      margin: 5px;

      @include breakpoint($desktop) {
        padding: 15px 25px;
        margin: 10px;
      }

      &.twitter {
        background: #55acef;
      }

      &.facebook {
        background: #3b5897;
      }
    }
  }
}
