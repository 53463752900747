.ReactModalPortal {
  position: absolute;
  z-index: 10001;

  // .ReactModal__Overlay {
  //   // background-color: rgba(43, 42, 42, 0.75) !important;
  // }
  .ReactModal__Content {
    background: $black !important;
    // border: none !important;

    // outline: none;
    // border-color: #ccc;
    // box-shadow: 0 0 10px #ccc;

    .performances-pref-modal-content {
      text-align: left;
      padding: 8px;

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          cursor: pointer;
          text-transform: uppercase;
          font-size: 0.9em;
          padding: 5px 10px 5px 5px;
          margin: 0 0 8px 0;
          background: #333;
          border: 1px #ccc dashed;

          &:hover {
            background: #555;
          }

          .set-pref-icon {
            height: 15px;
            margin-bottom: -2px;
            width: 15px;

            &.icon-color-green {
              color: $pref-green-color;
            }
            &.icon-color-gold {
              color: $primary-brand-color;
            }
          }
        }
      }
    }
  }
}
